import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  ACTION_TYPE_ICONS,
  Transition,
  useTransitionComponents,
} from 'entities/flow'

import styles from './TransitionComponent.module.css'

type Props = {
  transition: Transition
}

export const TransitionComponent = ({ transition }: Props) => {
  const { data: actions } = useTransitionComponents()

  if (!transition.initiator) return null

  const actionName = actions?.find(
    (item) => item.id === transition.actionId
  )?.name

  return (
    <div className={styles.wrapper}>
      {ACTION_TYPE_ICONS[transition.initiator.type]}
      <Box>
        <Typography>{actionName || '---'}</Typography>
      </Box>
    </div>
  )
}
