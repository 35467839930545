import {
  ReportAssessmentType,
  ReportAssessments,
  ReportFilterType,
} from 'widgets/report/types'
import classNames from 'classnames'

import s from './ReportAssessmentFilter.module.css'
import { getReportAssessmentAttributes } from 'entities/assessment'

interface ReportAssessmentFilterProps {
  assessments: ReportAssessments
  assessmentFilter: ReportFilterType
}

const reportAssessmentAttributes = getReportAssessmentAttributes()

export const ReportAssessmentFilter = ({
  assessmentFilter,
  assessments,
}: ReportAssessmentFilterProps) => {
  const renderItem = (type: ReportAssessmentType, position: number) => {
    const count = assessments[type]
    const isActiveFilter =
      assessmentFilter === 'all' || type.includes(assessmentFilter)

    const isInactive = !isActiveFilter || count === 0

    return (
      <div
        className={classNames(s.item)}
        style={{
          zIndex: 10 - position,
          right: (10 - position) * 20 - 70,
          backgroundColor: isInactive
            ? `rgba(239, 242, 246, 1)`
            : reportAssessmentAttributes[type].color,
        }}
      >
        {isInactive ? '' : count}
      </div>
    )
  }

  return (
    <div className={s.wrapper}>
      {renderItem('ux-good', 1)}
      {renderItem('ux-problem-low', 2)}
      {renderItem('ux-problem-medium', 3)}
      {renderItem('ux-problem-high', 4)}
      {renderItem('task', 5)}
      {renderItem('bug', 6)}
    </div>
  )
  // const renderItem = (type: ReportAssessmentType) => {
  //   // const count = Math.round(Math.random() * 35)
  //   const count = assessments[type]
  //   const isActiveFilter =
  //     assessmentFilter === 'all' || type.includes(assessmentFilter)
  //   const heightPercent = getHeightPercentByCount(count)

  //   const filterLabel =
  //     getReportAssessmentFilterOptions().find((item) => item.value === type)
  //       ?.name || ''

  //   return (
  //     <div
  //       className={classNames(s.item, s[type], {
  //         [s.activeItem]: isActiveFilter,
  //         [s.disabledItem]: count === 0,
  //       })}
  //     >
  //       <Tooltip placement="top" title={`${filterLabel}: ${count}`}>
  //         <div
  //           className={s.column}
  //           style={{
  //             height: `${heightPercent}%`,
  //           }}
  //         />
  //       </Tooltip>
  //     </div>
  //   )
  // }

  // return (
  //   <div className={s.wrapper}>
  //     {renderItem('ux-good')}
  //     {renderItem('ux-problem-low')}
  //     {renderItem('ux-problem-medium')}
  //     {renderItem('ux-problem-high')}
  //     {renderItem('task')}
  //     {renderItem('bug')}
  //   </div>
  // )
}
