import './App.css'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from 'shared/queryClient'
import { Routes } from 'pages'
import { SnackbarProvider } from 'notistack'
import { ProjectProvider } from 'features/head/context'

const App = () => (
  <SnackbarProvider>
    <QueryClientProvider client={queryClient}>
      <ProjectProvider>
        <Routes />
      </ProjectProvider>
    </QueryClientProvider>
  </SnackbarProvider>
)

export default App
