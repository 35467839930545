import { FilterAttribute } from 'entities/assessment'

import styles from './AssessmentPanelFilter.module.css'
import { FindSelectButton } from 'features/assessment/find/FindSelectButton'
import { AssessmentDigit } from 'features/assessment/fragment/ui/AssessmentDigit'
import { ReportAssessmentType } from 'widgets/report/types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

interface AssessmentPanelFilterProps {
  selectedFilters: string[]
  selectFilters: (value: string[]) => void
  filterAttributes: FilterAttribute<ReportAssessmentType | 'all'>[]
  multiple?: boolean
  className?: string
}

export const AssessmentPanelFilter = ({
  selectFilters,
  selectedFilters,
  filterAttributes,
  multiple,
  className,
}: AssessmentPanelFilterProps) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.wrapper, className)}>
      {filterAttributes.map((filterAttribute) => (
        <FindSelectButton
          key={filterAttribute.type}
          outlined
          icon={
            filterAttribute.type === 'all' ? t('all') : filterAttribute.icon
          }
          onClick={() => {
            if (filterAttribute.type === 'all') {
              selectFilters(['all'])
            } else {
              if (multiple) {
                const newFilters = (
                  selectedFilters.includes(filterAttribute.type)
                    ? selectedFilters.filter(
                        (item) => item !== filterAttribute.type
                      )
                    : selectedFilters.concat(filterAttribute.type)
                ).filter((item) => item !== 'all')

                if (
                  newFilters.length === 0 ||
                  newFilters.length === filterAttributes.length
                ) {
                  selectFilters(['all'])
                } else {
                  selectFilters(newFilters)
                }
              } else {
                selectFilters([filterAttribute.type])
              }
            }
          }}
          selected={selectedFilters.includes(filterAttribute.type)}
        >
          {filterAttribute.type === 'all' ? (
            filterAttribute.label
          ) : (
            <AssessmentDigit
              count={filterAttribute.label}
              type={filterAttribute.type}
              alwaysShowColor
              size="small"
              noPadding
            />
          )}
        </FindSelectButton>
      ))}
    </div>
  )
}
