import { useLocation } from 'react-router-dom'

type Permission = 'READ' | 'WRITE'

interface UsePermissionReturn {
  permission: Permission
}

export const usePermission = (): UsePermissionReturn => {
  const { pathname } = useLocation()

  const hasWritePermission = !pathname.includes('/project')

  return {
    permission: hasWritePermission ? 'WRITE' : 'READ',
  }
}

export const useHasWritePermission = () => {
  const { permission } = usePermission()

  return permission === 'WRITE'
}
