import { Typography } from '@mui/material'
import s from './AssessmentHead.module.css'
import { useTranslation } from 'react-i18next'
import {
  DEFAULT_OFFSET,
  TRANSITION_WIDTH,
  getNearestActionIdInColumn,
} from 'entities/flow'
import { useProject } from 'shared/model/projects'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

interface AssessmentHeadProps {
  screenId: string
  goToFlow: VoidFunction
}

export const AssessmentHead = ({ screenId, goToFlow }: AssessmentHeadProps) => {
  const { t } = useTranslation()

  const { data: project } = useProject()

  const screen = project?.flow.screens.find((item) => item.id === screenId)

  if (!project || !screen) return null

  const nearestActionId = getNearestActionIdInColumn({
    transitions: project.flow.transitions,
    position: {
      x: screen.position.x - DEFAULT_OFFSET - TRANSITION_WIDTH,
      y: screen.position.y - 1,
    },
  })

  const actionName = project.transitionComponents.find(
    (component) => component.id === nearestActionId
  )?.name

  return (
    <div className={s.wrapper}>
      <button onClick={goToFlow} className={s.iconWrapper}>
        <ArrowBackIcon fontSize="small" />
      </button>
      <div>
        <Typography className={s.actionName} variant="body2">
          <span className={s.gray}>{actionName}</span>
        </Typography>
        <Typography className={s.screenName} variant="body2">
          <span className={s.gray}>{t('page')}:</span> {screen.name}
        </Typography>
      </div>
    </div>
  )
}
