import {
  Assessment,
  useAddFinalReportAssessment,
  useExcludeAssessmentFromTotalAssessments,
  useHeuristics,
  useHeuristicsAndTags,
  useIncludeAssessmentToTotalAssessments,
} from 'entities/assessment'
import { Report } from '../../types'

import s from './ReportAssessmentBundling.module.css'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useProjectId } from 'shared/model/projects'
import classNames from 'classnames'
import { ReportScreenCanvas } from '../ReportScreenCanvas'

import { ReactComponent as BrainIcon } from '../../../../assets/brain-idea-mind-svgrepo-com.svg'
import {
  getCriticalityOptions,
  getFindTypeOptions,
} from 'features/assessment/find/lib'
import { FindSelect } from 'features/assessment/find/FindSelect'
import { HeuristicWithButton } from 'features/assessment/find/HeuristicWithButton'
import { useEffect, useMemo, useState } from 'react'
import { ReportDetailsContent } from '../ReportDetails/ReportDetailsContent'
import { HeuristicSearch } from 'features/assessment/heuristic/search'
import {
  defaultReportAssessmentBundlingFormValues,
  reportAssessmentBundlingValidationSchema,
} from './form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { ProjectData } from 'shared/types/projects'

interface ReportAssessmentBundlingProps {
  assessmentIds: string[] | undefined
  screenNumber: string | undefined
  screenVariantNumber: string | undefined
  screenVariantFragmentNumber: string | undefined
  report: Report
  isMobile: boolean
  assessmentType: string | undefined
  onClearInProgressAssessments: VoidFunction
  project: ProjectData
}

export const ReportAssessmentBundling = ({
  assessmentIds = [],
  report,
  screenNumber,
  screenVariantFragmentNumber,
  screenVariantNumber,
  isMobile,
  assessmentType,
  project,
  onClearInProgressAssessments,
}: ReportAssessmentBundlingProps) => {
  const { t } = useTranslation()

  const [activeHeuristicId, setActiveHeuristicId] = useState('')
  const [selectedHeuristicTags, selectHeuristicTags] = useState<string[]>([])

  const projectId = useProjectId()

  const screen = report.screens.find(
    (screen) => String(screen.number) === screenNumber
  )
  const screenVariant = screen?.screenVariants.find(
    (screenVariant) => String(screenVariant.number) === screenVariantNumber
  )
  const screenVariantFragment = screenVariant?.fragments.find(
    (fragment) => String(fragment.number) === screenVariantFragmentNumber
  )

  useEffect(() => {
    selectHeuristicTags([])
  }, [screenVariantFragment?.id])

  const { data } = useHeuristicsAndTags(projectId)
  const heuristics = data?.heuristics

  const assessments = useMemo(() => {
    return assessmentIds
      .map((assessmentId) => {
        const assessment = screenVariantFragment?.assessments.find(
          (item) => item.id === assessmentId
        )

        return assessment
      })
      .filter(Boolean) as Assessment[]
  }, [assessmentIds, screenVariantFragment?.assessments])

  const filteredByTagsHeuristics = useHeuristics({
    tags: selectedHeuristicTags,
    projectId,
  })

  const excludeAssessmentFromTotalAssessments =
    useExcludeAssessmentFromTotalAssessments()

  const includeAssessmentToTotalAssessments =
    useIncludeAssessmentToTotalAssessments()

  const addFinalReportAssessment = useAddFinalReportAssessment()

  const { handleSubmit, formState, setValue, watch, reset, trigger } = useForm({
    resolver: yupResolver(reportAssessmentBundlingValidationSchema),
    defaultValues: defaultReportAssessmentBundlingFormValues,
    mode: 'all',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    reset()
  }, [assessmentIds.length, reset])

  useEffect(() => {
    if (assessments.length > 0 && assessmentType) {
      const isAssessmentsHaveSameType = assessments.every(
        (item) => item.findType === assessmentType
      )

      if (isAssessmentsHaveSameType) {
        setTimeout(() => {
          setValue('findType', assessmentType, {
            shouldValidate: true,
          })
        }, 300)
      }
    }
  }, [assessmentType, assessments, setValue])

  const searchHeuristicId = watch('searchHeuristicId')

  const filteredHeuristics = useMemo(() => {
    return filteredByTagsHeuristics?.filter(
      (heuristic) =>
        (heuristic.evaluationArea === 'screen_and_fragment' ||
        !heuristic.evaluationArea
          ? true
          : screenVariantFragment?.root
          ? heuristic.evaluationArea === 'screen'
          : heuristic.evaluationArea === 'fragment') &&
        searchHeuristicId !== heuristic.id
    )
  }, [filteredByTagsHeuristics, screenVariantFragment?.root, searchHeuristicId])

  if (!screen || !screenVariant || !screenVariantFragment || !project) {
    return null
  }

  const findTypeOptions = getFindTypeOptions()

  const isGoodOrProblem =
    assessmentType === 'ux-good' || assessmentType === 'ux-problem'

  const findSelectOptions = isGoodOrProblem
    ? findTypeOptions.filter(
        (item) => item.value === 'ux-good' || item.value === 'ux-problem'
      )
    : []

  const criticalityOptions = getCriticalityOptions()

  const fullActiveHeuristic = data?.heuristicsResponse.find(
    (item) => item.id === activeHeuristicId
  )

  const recommendation: any = Array.isArray(
    fullActiveHeuristic?.field_recommendation_how_to_fix
  )
    ? fullActiveHeuristic?.field_recommendation_how_to_fix?.[0]
    : null

  const selectedSearchFormHeuristic = heuristics?.find(
    (item) => item.id === searchHeuristicId
  )

  const formFindType = watch('findType')

  const isUxProblem = formFindType === 'ux-problem'

  const isShowCriticality = () => {
    return formFindType === 'ux-problem' || formFindType === 'task'
  }

  const isShowRecommendation = () => {
    return true
  }

  const isExcludedAssessment =
    project.excludedAssessmentIds?.some((id) => assessmentIds.includes(id)) ??
    false

  const customFindName = watch('customFindName')
  const customFindDescription = watch('customFindDescription')
  const customRecommendation = watch('customRecommendation')
  const heuristicId = watch('heuristicId')

  return (
    <div className={s.wrapper}>
      <div
        className={classNames(s.fragmentImage, {
          [s.fragmentImageMobile]: isMobile,
        })}
      >
        <ReportScreenCanvas
          screenId={screenVariant.screenId}
          fragmentId={screenVariantFragment.id}
          assessmentId={undefined}
          project={project}
          report={report}
        />
      </div>
      <form
        noValidate
        onSubmit={handleSubmit((values) => {
          addFinalReportAssessment({
            assessment: {
              comment: values.findDescription,
              findType: values.findType,
              type: values.criticality,
              heuristicId: values.heuristicId,
              recommendation: values.recommendation,
              name: values.findName,
              tags: [],
            },
            fragment: screenVariantFragment,
            screenId: screenVariant.screenId,
            relatedAssessmentIds: assessmentIds,
            onSuccess: onClearInProgressAssessments,
          })
        })}
      >
        {assessments.length !== 0 && (
          <div className={s.innerContent}>
            <div className={s.columns}>
              {assessments.map((assessment) => {
                return (
                  <div key={assessment.id} className={s.column}>
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 1,
                        opacity: 0.6,
                      }}
                    >
                      {assessment.user?.fio || 'Иван Васильевич'}
                    </Typography>
                  </div>
                )
              })}
            </div>
            <Typography
              className={s.title}
              sx={{
                mb: 1,
                mt: 2,
              }}
            >
              {t('heuristic')}
            </Typography>
            <div className={s.columns}>
              {assessments.map((assessment) => {
                const heuristic = assessment.heuristicId
                  ? heuristics?.find(
                      (item) => item.id === assessment.heuristicId
                    )
                  : undefined

                if (!heuristic) return null

                const isNegative = isUxProblem
                const heuristicName = isNegative
                  ? heuristic?.nameNegative
                  : heuristic?.name

                return (
                  <div key={assessment.id} className={s.column}>
                    <FormControlLabel
                      value={heuristic.id}
                      control={<Radio />}
                      label={
                        <HeuristicWithButton
                          name={heuristicName}
                          onTextClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            setActiveHeuristicId(heuristic.id)
                          }}
                          withoutButton
                        />
                      }
                      onChange={() => {
                        setValue('heuristicId', heuristic.id)
                        setValue('searchHeuristicId', '')
                        trigger()
                      }}
                      checked={
                        heuristicId === heuristic.id && !searchHeuristicId
                      }
                      classes={{
                        label: classNames(s.radioLabel, {
                          [s.groupedHeuristicLabel]:
                            assessment.hasHeuristicGroup,
                        }),
                        root: s.radioWrapper,
                      }}
                    />
                  </div>
                )
              })}
            </div>
            <div className={classNames(s.flexWrapper, s.heuristicsWrapper)}>
              {projectId && (
                <FormControlLabel
                  label={
                    <HeuristicSearch
                      selectTags={selectHeuristicTags}
                      selectedTags={selectedHeuristicTags}
                      placeholder={t('enterTag')}
                      label={t('searchHeuristicsByTags')}
                      projectId={projectId}
                      selectHeuristicId={(heuristicId) => {
                        setValue('heuristicId', heuristicId, {
                          shouldValidate: true,
                        })
                        setValue('searchHeuristicId', heuristicId)
                      }}
                      heuristics={filteredHeuristics}
                      isNegative={isUxProblem}
                      isRootFragment={!!screenVariantFragment.root}
                    />
                  }
                  control={<Radio />}
                  checked={Boolean(
                    heuristicId &&
                      searchHeuristicId &&
                      heuristicId === searchHeuristicId
                  )}
                  disabled
                />
              )}
              {selectedSearchFormHeuristic && (
                <HeuristicWithButton
                  name={
                    isUxProblem
                      ? selectedSearchFormHeuristic.nameNegative
                      : selectedSearchFormHeuristic.name
                  }
                  onTextClick={(e) => {
                    e.stopPropagation()
                    setActiveHeuristicId(selectedSearchFormHeuristic.id)
                  }}
                  onButtonClick={(e) => {
                    e.stopPropagation()
                    if (heuristicId === searchHeuristicId) {
                      setValue('heuristicId', '')
                    }
                    setValue('searchHeuristicId', '')
                  }}
                  mode="remove"
                />
              )}
            </div>
            {selectedHeuristicTags.length > 0 && (
              <Box
                sx={{
                  mt: 2,
                  maxHeight: '160px',
                  overflowY: 'auto',
                  flexWrap: 'nowrap',
                  mb: 2,
                  display: searchHeuristicId ? 'none' : 'block',
                }}
              >
                {filteredHeuristics?.map((heuristic) => (
                  <HeuristicWithButton
                    key={heuristic.id}
                    mode="add"
                    name={isUxProblem ? heuristic.nameNegative : heuristic.name}
                    onTextClick={() => setActiveHeuristicId(heuristic.id)}
                    onButtonClick={() => {
                      setValue('heuristicId', heuristic.id, {
                        shouldValidate: true,
                      })
                      setValue('searchHeuristicId', heuristic.id)
                    }}
                  />
                ))}
              </Box>
            )}
            <Typography
              className={s.title}
              sx={{
                mb: 1,
                mt: 2,
              }}
            >
              <span className={s.redStar}>*</span>
              {t('findType')}
            </Typography>
            <div className={s.columns}>
              {assessments.map((assessment) => {
                return (
                  <div key={assessment.id} className={s.column}>
                    <Typography
                      classes={{
                        root: classNames(s.radioLabel, {
                          [s.groupedHeuristicLabel]:
                            assessment.hasHeuristicGroup,
                        }),
                      }}
                      sx={{
                        mb: 1,
                      }}
                    >
                      {findTypeOptions.find(
                        (item) => item.value === assessment.findType
                      )?.name || ''}
                    </Typography>
                  </div>
                )
              })}
            </div>
            {findSelectOptions.length > 0 && (
              <div className={s.findSelectWrapper}>
                <FindSelect
                  label={
                    <>
                      <span className={s.redStar}>*</span>
                      {t('finalFindType')}
                    </>
                  }
                  value={formFindType}
                  onChange={(value) => {
                    setValue('findType', value)
                    trigger()
                  }}
                  options={findSelectOptions}
                  error={false}
                />
              </div>
            )}
            <div className={s.columns}>
              {assessments.map((assessment, index) => {
                const heuristic = assessment.heuristicId
                  ? heuristics?.find(
                      (item) => item.id === assessment.heuristicId
                    )
                  : undefined
                const isNegative = assessment.findType === 'ux-problem'
                const heuristicName = isNegative
                  ? heuristic?.nameNegative
                  : heuristic?.name

                const assessmentName = assessment.name || heuristicName || ''

                return (
                  <div key={assessment.id} className={s.column}>
                    <Typography
                      className={s.title}
                      sx={{
                        mb: 1,
                        opacity: index === 0 ? 1 : 0,
                      }}
                    >
                      <span className={s.redStar}>*</span>
                      {t('nameBig')}
                    </Typography>
                    <FormControlLabel
                      value={assessmentName}
                      control={<Radio />}
                      label={assessmentName}
                      onChange={() => {
                        setValue('findName', assessmentName)
                        trigger()
                      }}
                      checked={watch('findName') === assessmentName}
                      classes={{
                        label: classNames(s.radioLabel, {
                          [s.groupedHeuristicLabel]:
                            assessment.hasHeuristicGroup,
                        }),
                        root: s.radioWrapper,
                      }}
                    />
                  </div>
                )
              })}
            </div>
            <div className={s.flexWrapper}>
              <FormControlLabel
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      flexFlow: 'row',
                      gap: 2,
                      alignItems: 'flex-start',
                    }}
                  >
                    <TextField
                      multiline
                      minRows={2}
                      size="small"
                      label={t('yourVersion')}
                      value={customFindName}
                      onChange={(e) => {
                        setValue('customFindName', e.target.value)
                        setValue('findName', e.target.value)
                      }}
                      fullWidth
                    />
                    <Button
                      variant="outlined"
                      startIcon={<BrainIcon className={s.brainIcon} />}
                      size="large"
                      className={s.gptButton}
                      onClick={() => {
                        const newCustomFindName = customFindName
                          ? customFindName +
                            '\nСвой вариантик такой прекрасный веселый могучий'
                          : 'Свой вариантик такой прекрасный веселый могучий'
                        setValue('customFindName', newCustomFindName)
                        setValue('findName', newCustomFindName)
                      }}
                    >
                      {t('gptVersion')}
                    </Button>
                  </Box>
                }
                control={<Radio />}
                classes={{
                  label: s.customLabel,
                }}
                onChange={() => {
                  setValue('findName', customFindName)
                  trigger()
                }}
                sx={{
                  mt: 2,
                  width: '100%',
                }}
                checked={watch('findName') === customFindName}
              />
            </div>
            <Typography
              className={s.title}
              sx={{
                mb: 1,
                mt: 2,
              }}
            >
              <span className={s.redStar}>*</span>
              {t('description')}
            </Typography>
            <div className={s.columns}>
              {assessments.map((assessment) => {
                const heuristic = assessment.heuristicId
                  ? heuristics?.find(
                      (item) => item.id === assessment.heuristicId
                    )
                  : undefined
                const heuristicDescription = heuristic?.description

                const assessmentDescription =
                  assessment.comment || heuristicDescription || ''

                return (
                  <div key={assessment.id} className={s.column}>
                    <FormControlLabel
                      value={assessmentDescription}
                      control={<Radio />}
                      label={assessmentDescription}
                      onChange={() => {
                        setValue('findDescription', assessmentDescription)
                        trigger()
                      }}
                      checked={
                        watch('findDescription') === assessmentDescription
                      }
                      classes={{
                        label: classNames(s.radioLabel, {
                          [s.groupedHeuristicLabel]:
                            assessment.hasHeuristicGroup,
                        }),
                        root: s.radioWrapper,
                      }}
                    />
                  </div>
                )
              })}
            </div>
            <div className={s.flexWrapper}>
              <FormControlLabel
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      flexFlow: 'row',
                      gap: 2,
                      alignItems: 'flex-start',
                    }}
                  >
                    <TextField
                      multiline
                      minRows={2}
                      size="small"
                      label={t('yourVersion')}
                      value={customFindDescription}
                      onChange={(e) => {
                        setValue('customFindDescription', e.target.value)
                        setValue('findDescription', e.target.value)
                      }}
                      fullWidth
                    />
                    <Button
                      variant="outlined"
                      startIcon={<BrainIcon className={s.brainIcon} />}
                      size="large"
                      className={s.gptButton}
                      onClick={() => {
                        const newCustomFindDescription = customFindDescription
                          ? customFindDescription +
                            '\nСвой вариантик такой прекрасный веселый могучий'
                          : 'Свой вариантик такой прекрасный веселый могучий'
                        setValue(
                          'customFindDescription',
                          newCustomFindDescription
                        )
                        setValue('findDescription', newCustomFindDescription)
                      }}
                    >
                      {t('gptVersion')}
                    </Button>
                  </Box>
                }
                control={<Radio />}
                onChange={() => {
                  setValue('findDescription', customFindDescription)
                  trigger()
                }}
                sx={{
                  mt: 2,
                  width: '100%',
                }}
                classes={{
                  label: s.customLabel,
                }}
                checked={watch('findDescription') === customFindDescription}
              />
            </div>

            {isShowCriticality() && (
              <>
                <Typography
                  className={s.title}
                  sx={{
                    mb: 1,
                    mt: 2,
                  }}
                >
                  {t('criticality')}
                </Typography>
                <div className={s.columns}>
                  {assessments.map((assessment) => {
                    const getCriticality = () => {
                      if (!isGoodOrProblem) return null

                      return assessment.type
                    }

                    const criticality = getCriticality()
                    const criticalityLabel = criticality
                      ? criticalityOptions.find(
                          (item) => item.value === criticality
                        )?.name
                      : '—'

                    return (
                      <div
                        key={assessment.id}
                        className={classNames(s.column, {
                          [s.flexWrapper]: true,
                          [s.flexCenter]: true,
                          [s.criticalityWrapper]: true,
                        })}
                      >
                        {criticality && (
                          <div
                            className={classNames(s.circle, {
                              [s[criticality]]: true,
                            })}
                          />
                        )}
                        <Typography
                          classes={{
                            root: classNames({
                              [s.groupedHeuristicLabel]:
                                assessment.hasHeuristicGroup,
                            }),
                          }}
                        >
                          {criticalityLabel}
                        </Typography>
                      </div>
                    )
                  })}
                </div>
                <div className={s.findSelectWrapper}>
                  <FindSelect
                    label={
                      <>
                        <span className={s.redStar}>*</span>
                        {t('finalCriticality')}
                      </>
                    }
                    value={watch('criticality')}
                    onChange={(value) => {
                      setValue('criticality', value)
                      trigger()
                    }}
                    options={criticalityOptions}
                    error={false}
                  />
                </div>
              </>
            )}
            {isShowRecommendation() && (
              <>
                <Typography
                  className={s.title}
                  sx={{
                    mb: 1,
                    mt: 2,
                  }}
                >
                  <span className={s.redStar}>*</span>
                  {t('recommendation')}
                </Typography>
                <div className={s.columns}>
                  {assessments.map((assessment) => {
                    const heuristic = assessment.heuristicId
                      ? heuristics?.find(
                          (item) => item.id === assessment.heuristicId
                        )
                      : undefined
                    const heuristicRecommendation = heuristic?.recommendation

                    const assessmentRecommendation =
                      assessment.recommendation || heuristicRecommendation || ''

                    return (
                      <div key={assessment.id} className={s.column}>
                        <FormControlLabel
                          value={assessmentRecommendation}
                          control={<Radio />}
                          label={assessmentRecommendation}
                          checked={
                            watch('recommendation') === assessmentRecommendation
                          }
                          onChange={() => {
                            setValue('recommendation', assessmentRecommendation)
                            trigger()
                          }}
                          classes={{
                            label: classNames(s.radioLabel, {
                              [s.groupedHeuristicLabel]:
                                assessment.hasHeuristicGroup,
                            }),
                            root: s.radioWrapper,
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
                <div className={s.flexWrapper}>
                  <FormControlLabel
                    label={
                      <Box
                        sx={{
                          display: 'flex',
                          flexFlow: 'row',
                          gap: 2,
                          alignItems: 'flex-start',
                        }}
                      >
                        <TextField
                          multiline
                          minRows={2}
                          size="small"
                          label={t('yourVersion')}
                          value={customRecommendation}
                          onChange={(e) => {
                            setValue('customRecommendation', e.target.value)
                            setValue('recommendation', e.target.value)
                          }}
                          fullWidth
                        />
                        <Button
                          variant="outlined"
                          startIcon={<BrainIcon className={s.brainIcon} />}
                          size="large"
                          className={s.gptButton}
                          onClick={() => {
                            const newCustomRecommendation = customRecommendation
                              ? customRecommendation +
                                '\nСвой вариантик такой прекрасный веселый могучий'
                              : 'Свой вариантик такой прекрасный веселый могучий'
                            setValue(
                              'customRecommendation',
                              newCustomRecommendation
                            )
                            setValue('recommendation', newCustomRecommendation)
                          }}
                        >
                          {t('gptVersion')}
                        </Button>
                      </Box>
                    }
                    control={<Radio />}
                    onChange={() => {
                      setValue('recommendation', customRecommendation)
                      trigger()
                    }}
                    sx={{
                      mt: 2,
                      width: '100%',
                    }}
                    checked={watch('recommendation') === customRecommendation}
                    classes={{
                      label: s.customLabel,
                    }}
                  />
                </div>
              </>
            )}
          </div>
        )}

        <div className={s.bottomButtons}>
          {assessments.length !== 0 && (
            <>
              <Button
                variant="contained"
                size="large"
                type="submit"
                disabled={!formState.isValid}
              >
                {t('saveToTotalAssessment')}
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  if (isExcludedAssessment) {
                    includeAssessmentToTotalAssessments(assessmentIds)
                  } else {
                    excludeAssessmentFromTotalAssessments(assessmentIds)
                    onClearInProgressAssessments()
                  }
                }}
              >
                {isExcludedAssessment
                  ? t('includeInTheFinalReport')
                  : t('dontIncludeInTheFinalReport')}
              </Button>
            </>
          )}

          <Button
            variant="contained"
            size="large"
            classes={{
              root: s.completeButton,
            }}
          >
            {t('completeTheFinalReportBuilding')}
          </Button>
        </div>
      </form>

      {fullActiveHeuristic && projectId && recommendation && (
        <Dialog
          maxWidth="lg"
          fullWidth
          open
          onClose={() => setActiveHeuristicId('')}
        >
          <DialogContent>
            <ReportDetailsContent
              heuristic={fullActiveHeuristic}
              projectId={projectId}
              recommendation={recommendation}
              onClose={() => setActiveHeuristicId('')}
              className={s.reportDetailsContent}
              report={report}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}
