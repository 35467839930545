import { RemoteProjectMeta } from 'shared/types/projects'

export const isEnglishProject = (projectId: string | undefined) => {
  const encodedProjectMeta = localStorage.getItem(`metaData-${projectId}`)
  const decodedProjectMeta: RemoteProjectMeta | null = encodedProjectMeta
    ? JSON.parse(encodedProjectMeta)
    : null

  return decodedProjectMeta?.language.toLowerCase() === 'en'
}
