import { ScreenFragment, User } from 'entities/assessment'
import { ProjectData } from 'shared/types/projects'

const getUserByProject = (project: ProjectData): User => ({
  id: `user-${project.id}`,
  fio: project.id.substring(0, 8),
})

interface GetScreenFragmentsByProjectsParams {
  projects: ProjectData[]
  screenId: string
}

export const getScreenFragmentsByProjects = ({
  projects,
  screenId,
}: GetScreenFragmentsByProjectsParams): ScreenFragment[] => {
  let allScreenFragments: ScreenFragment[] = []

  projects.forEach((project) => {
    const projectScreenFragments = project.screenFragments[screenId] || []
    const projectScreenFragmentsWithUser = projectScreenFragments.map(
      (screenFragment) => {
        const user: User = getUserByProject(project)

        return {
          ...screenFragment,
          user,
        }
      }
    )
    allScreenFragments = allScreenFragments.concat(
      projectScreenFragmentsWithUser
    )
  })

  const screenFragmentIds = allScreenFragments.map((item) => item.id)
  const uniqueScreenFragmentIds = [...new Set(screenFragmentIds)]

  const uniqueScreenFragmentsWithAllProjectAssessments: ScreenFragment[] = []

  uniqueScreenFragmentIds.forEach((fragmentId) => {
    const screenFragmentsById = allScreenFragments.filter(
      (item) => item.id === fragmentId
    )

    const basicScreenFragment: ScreenFragment = {
      ...screenFragmentsById[0],
      user:
        screenFragmentsById.length > 1
          ? undefined
          : screenFragmentsById[0].user,
      assessments: [],
    }

    screenFragmentsById.forEach((screenFragment) => {
      const screenFragmentAssessmentsWithUser = screenFragment.assessments.map(
        (assessment) => ({
          ...assessment,
          user: screenFragment.user,
        })
      )

      basicScreenFragment.assessments = basicScreenFragment.assessments.concat(
        screenFragmentAssessmentsWithUser
      )
    })

    uniqueScreenFragmentsWithAllProjectAssessments.push(basicScreenFragment)
  })

  uniqueScreenFragmentsWithAllProjectAssessments.forEach((screenFragment) => {
    const heuristicIdCountMap = new Map<string, number>()

    screenFragment.assessments.forEach((assessment) => {
      if (assessment.heuristicId) {
        const value = heuristicIdCountMap.get(assessment.heuristicId)
        const newValue = value ? value + 1 : 1

        heuristicIdCountMap.set(assessment.heuristicId, newValue)
      }
    })

    screenFragment.assessments.forEach((assessment) => {
      const mapValue = heuristicIdCountMap.get(assessment.heuristicId)

      if (mapValue && mapValue > 1) {
        assessment.hasHeuristicGroup = true
      }
    })
  })

  return uniqueScreenFragmentsWithAllProjectAssessments
}
