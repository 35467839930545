import { useEffect, useState } from 'react'
import styles from './AssessmentPanel.module.css'
import { Button, Tab, Tabs, Typography } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import {
  ScreenFragment,
  useHeuristics,
  useScreenFragments,
} from 'entities/assessment'
import { HeuristicSearch } from 'features/assessment/heuristic/search'
import { AssessmentPanelFilter } from 'features/assessment/filter'
import {
  getAssessmentFilterAttributes,
  getHeuristicFilterAttributes,
} from '../config'
import {
  AssessmentFragment,
  AssessmentFragmentTitle,
} from 'features/assessment/fragment'
import { TabType } from 'pages/assessment'
import { useHasWritePermission } from 'entities/permissions'
import i18n from 'shared/i18n/i18n'
import { useProjectContext } from 'features/head/context'
import { FindForm } from 'features/assessment/find/FindForm'
import { useFlow } from 'entities/flow'
import classNames from 'classnames'

interface AssessmentPanelProps {
  screenId: string
  fragmentId: string
  setFragmentId: (value: string) => void
  tab: TabType
  setTab: (tab: TabType) => void
  projectId: string
  activeHeuristicId: string
  setActiveHeuristicId: (value: string) => void
}

export const AssessmentPanel = ({
  screenId,
  fragmentId,
  setFragmentId,
  tab,
  setTab,
  projectId,
  activeHeuristicId,
  setActiveHeuristicId,
}: AssessmentPanelProps) => {
  const [selectedAssessmentFilters, selectAssessmentFilters] = useState<
    string[]
  >(['all'])

  const fragments = useScreenFragments({ screenId })

  const { data: flow } = useFlow()

  const screen = flow?.screens.find((item) => item.id === screenId)

  const hasWritePermission = useHasWritePermission()

  useEffect(() => {
    if (fragments?.length === 1) {
      const rootFragment = fragments.find((item) => item.root)

      if (rootFragment) {
        setFragmentId(rootFragment.id)
      }
    }
  }, [fragments, setFragmentId])

  const fragment = fragments?.find((item) => item.id === fragmentId)

  const filteredAssessments = fragment?.assessments?.filter((assessment) => {
    if (selectedAssessmentFilters.includes('all')) return true

    if (assessment.findType === 'ux-problem') {
      if (assessment.type === 'good')
        return selectedAssessmentFilters.includes('ux-problem-low')
      if (assessment.type === 'medium')
        return selectedAssessmentFilters.includes('ux-problem-medium')
      if (assessment.type === 'bad')
        return selectedAssessmentFilters.includes('ux-problem-high')
    }

    return selectedAssessmentFilters.includes(assessment.findType)
  })

  const { isAssessment, isMaster } = useProjectContext()

  const rootFragment = fragments?.find((item) => item.root)
  const notRootFragments = fragments?.filter((item) => !item.root)

  const totalFragment: ScreenFragment | undefined = fragments?.reduce<
    ScreenFragment | undefined
  >((total, currentFragment) => {
    if (!total) return { ...currentFragment, name: '' }

    return {
      ...total,
      assessments: total.assessments.concat(currentFragment.assessments),
    }
  }, undefined)

  const withoutGlobalPadding = tab === 'results' && fragmentId

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperWithPadding]: !withoutGlobalPadding,
      })}
    >
      {fragmentId ? (
        <>
          <div className={withoutGlobalPadding ? styles.paddingContainer : ''}>
            <Button
              variant="text"
              startIcon={<KeyboardBackspaceIcon />}
              sx={{
                mb: 1,
                alignSelf: 'flex-start',
              }}
              onClick={() => setFragmentId('')}
            >
              {i18n.t('toFragmentsList')}
            </Button>
            {fragment && (
              <Typography
                sx={{
                  fontSize: '20px',
                }}
              >
                {fragment.name}
              </Typography>
            )}
            {isAssessment && (
              <Tabs
                value={tab}
                sx={{
                  mt: 2,
                  mb: 2,
                }}
                onChange={(_, newSelectedActionTab) =>
                  setTab(newSelectedActionTab)
                }
              >
                {hasWritePermission && (
                  <Tab
                    classes={{
                      selected: styles.selectedTab,
                    }}
                    value="search"
                    label={i18n.t('newFind')}
                  />
                )}
                <Tab
                  classes={{
                    selected: styles.selectedTab,
                  }}
                  value="results"
                  label={`${i18n.t('finds')} (${
                    fragment?.assessments?.length ?? 0
                  })`}
                />
              </Tabs>
            )}
          </div>

          {tab === 'search' && isAssessment && (
            <>
              <FindForm
                activeHeuristicId={activeHeuristicId}
                setActiveHeuristicId={setActiveHeuristicId}
                screenId={screenId}
                fragmentId={fragmentId}
              />
            </>
          )}
          {tab === 'results' && isAssessment && (
            <>
              <div className={styles.searchAndFilter}>
                <AssessmentPanelFilter
                  selectedFilters={selectedAssessmentFilters}
                  selectFilters={selectAssessmentFilters}
                  filterAttributes={
                    fragment?.assessments
                      ? getAssessmentFilterAttributes(fragment.assessments)
                      : []
                  }
                  multiple
                />
              </div>

              {!!filteredAssessments?.length && (
                <div className={styles.findForms}>
                  {filteredAssessments?.map((assessment, index) => (
                    <FindForm
                      key={assessment.id}
                      assessment={assessment}
                      activeHeuristicId={activeHeuristicId}
                      setActiveHeuristicId={setActiveHeuristicId}
                      screenId={screenId}
                      fragmentId={fragmentId}
                      onListPlacement
                      number={index + 1}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <AssessmentFragmentTitle title={i18n.t('mainPage')} />
          {rootFragment && (
            <AssessmentFragment
              fragment={rootFragment}
              onClick={() => setFragmentId(rootFragment.id)}
              inputEditable={isMaster}
              screenId={screenId}
              clickable={isAssessment && !isMaster}
              deletable={false}
              name={screen?.name}
            />
          )}
          <AssessmentFragmentTitle
            title={i18n.t('fragments')}
            className={styles.noRootFragmentsTitle}
          />
          {notRootFragments?.map((fragment) => (
            <AssessmentFragment
              key={fragment.id}
              fragment={fragment}
              onClick={() => setFragmentId(fragment.id)}
              screenId={screenId}
              clickable={isAssessment && !isMaster}
              inputEditable={Boolean(isMaster || fragment.isCustom)}
              deletable={Boolean(isMaster || fragment.isCustom)}
            />
          ))}
          <AssessmentFragmentTitle
            title={i18n.t('total')}
            className={styles.totalTitle}
          />
          {totalFragment && (
            <AssessmentFragment
              fragment={totalFragment}
              onClick={() => null}
              inputEditable={false}
              screenId={screenId}
              clickable={false}
              deletable={false}
            />
          )}
        </>
      )}
    </div>
  )
}
