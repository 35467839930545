import { ScreenFragment } from 'entities/assessment'
import { fabric } from 'fabric'

interface LoadImageParams {
  imageUrl: string
  setImage: (value: fabric.Image) => void
}

export const loadImage = ({ imageUrl, setImage }: LoadImageParams) => {
  fabric.Image.fromURL(imageUrl, (oImg) => {
    oImg.set('selectable', false)
    oImg.set('objectCaching', false)
    setImage(oImg)
  })
}

const RECT_TYPE = 'rect'

interface CreateFabricRectParams {
  left: number
  top: number
  width: number
  height: number
  fragment?: ScreenFragment
}

const createFabricRect = ({
  height,
  left,
  top,
  width,
  fragment,
}: CreateFabricRectParams) =>
  new fabric.Rect({
    left,
    top,
    originX: 'left',
    originY: 'top',
    width,
    height,
    angle: 0,
    fill: 'transparent',
    transparentCorners: false,
    selectable: false,
    type: RECT_TYPE,
    stroke: '#403af0',
    strokeWidth: 3,
    data: fragment ? createFragmentData(fragment) : undefined,
  })

const createFragmentData = (fragment: ScreenFragment) => ({
  id: fragment.id,
})

interface CreateMarkerAndRectParams {
  fragment: ScreenFragment
}

const createMarkerAndRect = ({ fragment }: CreateMarkerAndRectParams) => {
  const rect = createFabricRect({
    height: fragment.height,
    left: fragment.position.x,
    top: fragment.position.y,
    width: fragment.width,
    fragment,
  })

  rect.set('opacity', 0.4)
  rect.set('selectable', false)
  rect.set('lockRotation', true)
  rect.set('hasRotatingPoint', false)

  return {
    rect,
  }
}

interface DrawReportCanvasParams {
  canvas: fabric.Canvas
  fragment: ScreenFragment
}

export const drawReportCanvas = ({
  canvas,
  fragment,
}: DrawReportCanvasParams) => {
  const imgElement = document.getElementById('canvas-image')

  if (imgElement) {
    const imgInstance = new fabric.Image(imgElement as any, {
      left: 0,
      top: 0,
      selectable: false,
    })
    canvas.add(imgInstance)
  }

  if (fragment.height && fragment.width) {
    const topMaskRect = new fabric.Rect({
      left: 0,
      top: 0,
      originX: 'left',
      originY: 'top',
      width: canvas.width,
      height: fragment.position.y,
      fill: 'rgba(0, 0, 0, 0.5)',
      transparentCorners: false,
      selectable: false,
    })

    const bottomMaskRect = new fabric.Rect({
      left: 0,
      top: fragment.position.y + fragment.height,
      originX: 'left',
      originY: 'top',
      width: canvas.width,
      height: Number(canvas.height) - fragment.position.y,
      fill: 'rgba(0, 0, 0, 0.5)',
      transparentCorners: false,
      selectable: false,
    })

    const leftMaskRect = new fabric.Rect({
      left: 0,
      top: fragment.position.y,
      originX: 'left',
      originY: 'top',
      width: fragment.position.x,
      height: fragment.height,
      fill: 'rgba(0, 0, 0, 0.5)',
      transparentCorners: false,
      selectable: false,
    })

    const rightMaskRect = new fabric.Rect({
      left: fragment.position.x + fragment.width,
      top: fragment.position.y,
      originX: 'left',
      originY: 'top',
      width: Number(canvas.width) - fragment.position.x,
      height: fragment.height,
      fill: 'rgba(0, 0, 0, 0.5)',
      transparentCorners: false,
      selectable: false,
    })

    canvas.add(topMaskRect)
    canvas.add(bottomMaskRect)
    canvas.add(leftMaskRect)
    canvas.add(rightMaskRect)
  }

  canvas.renderAll()
}
