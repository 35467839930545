import s from './ReportFilters.module.css'
import { ReportAssessments, ReportFilterType } from 'widgets/report/types'
import { AssessmentPanelFilter } from 'features/assessment/filter'
import { getAssessmentReportFilterAttributes } from 'widgets/assessment/panel/config'

interface ReportFiltersProps {
  filter: ReportFilterType
  setFilter: (value: ReportFilterType) => void
  assessments: ReportAssessments
}

export const ReportFilters = ({
  filter,
  setFilter,
  assessments,
}: ReportFiltersProps) => {
  const options = getAssessmentReportFilterAttributes(assessments)

  return (
    <AssessmentPanelFilter
      selectedFilters={[filter]}
      selectFilters={(value) => setFilter((value as any)[0])}
      filterAttributes={options}
      className={s.filter}
    />
  )
}
