import { Checkbox, Dialog, FormControlLabel, FormGroup } from '@mui/material'
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

interface ProjectContextValues {
  isMaster: boolean
  isAssessment: boolean
  toggleMenu: VoidFunction
  setAssessment: (value: boolean) => void
}

export const ProjectContext = createContext<ProjectContextValues>({
  isMaster: true,
  isAssessment: false,
  toggleMenu: () => {},
  setAssessment: () => {},
})

export const ProjectProvider = (props: PropsWithChildren) => {
  const isReport = window.location.pathname.includes('/r/')
  const isReportBundling = window.location.pathname.includes('/rt/')

  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isMaster, setMaster] = useState(isReportBundling)
  const [isAssessment, setAssessment] = useState(!isReport || isReportBundling)
  const toggleMenu = () => setMenuOpen((prevState) => !prevState)

  return (
    <>
      <ProjectContext.Provider
        value={{
          toggleMenu,
          isAssessment,
          isMaster,
          setAssessment,
        }}
      >
        {props.children}
        {isMenuOpen && (
          <Dialog open onClose={toggleMenu}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isMaster}
                    onChange={(e) => setMaster(e.target.checked)}
                  />
                }
                label="Мастер сценарий"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAssessment}
                    onChange={(e) => {
                      setAssessment(e.target.checked)

                      if (e.target.checked && isMaster) {
                        window.location.href = window.location.href
                          .replace('/a/', '/rt/')
                          .replace('/r/', '/rt/')
                      }
                    }}
                  />
                }
                label="Оценка"
              />
            </FormGroup>
          </Dialog>
        )}
      </ProjectContext.Provider>
    </>
  )
}

export const useProjectContext = () => useContext(ProjectContext)
