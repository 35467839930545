import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useProject,
  useProjectId,
  useRemoteProjectRefresh,
} from 'shared/model/projects'
import { generateReport } from '../model'
import { Report, ReportDetailsCoord, ReportFilterType } from '../types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import s from './ReportPage.module.css'
import { ReportAssessment } from './ReportAssessment'
import { ReportFragment } from './ReportFragment/ReportFragment'
import { ReportDetails } from './ReportDetails/ReportDetails'
import { RemoteProjectMeta } from 'shared/types/projects'
import { useNavigate, useParams } from 'react-router-dom'
import { ReportAssessmentFilter } from './ReportAssessmentFilter/ReportAssessmentFilter'
import { ReportFilters } from './ReportFilters/ReportFilters'
import { ProjectHead } from 'features/head/ProjectHead'
import { ReportAssessmentBundling } from './ReportAssessmentBundling/ReportAssessmentBundling'
import { useHeuristicsAndTags } from 'entities/assessment'

interface RouteParams {
  screenNumber: string | undefined
  screenVariantNumber: string | undefined
  screenVariantFragmentNumber: string | undefined
  assessmentId: string | undefined
  [key: string]: string | undefined
}

const usePreparedRouteParams = () => {
  const {
    screenNumber,
    assessmentId,
    screenVariantNumber,
    screenVariantFragmentNumber,
  } = useParams<RouteParams>()

  return {
    screenNumber: Number(screenNumber),
    screenVariantNumber: Number(screenVariantNumber),
    screenVariantFragmentNumber: Number(screenVariantFragmentNumber),
    assessmentId: String(assessmentId),
  }
}

interface ProgressTotalAssessments {
  ids: string[]
  assessmentType: string
}

interface ReportPageProps {
  isTotalAssessment?: boolean
}

export const ReportPage = ({ isTotalAssessment = false }: ReportPageProps) => {
  const projectId = useProjectId()
  const [report, setReport] = useState<Report | null>(null)

  const [tab, setTab] = useState<'assessment' | 'totalAssessment'>('assessment')

  const reportPathPrefix = isTotalAssessment ? 'rt' : 'r'

  const [inProgressTotalAssessments, setInProgressTotalAssessments] =
    useState<ProgressTotalAssessments | null>(null)

  const [assessmentFilter, setAssessmentFilter] =
    useState<ReportFilterType>('all')

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { data: project } = useProject()

  const { data: heuristicsData } = useHeuristicsAndTags(projectId)

  useRemoteProjectRefresh(isTotalAssessment)

  const {
    screenNumber,
    assessmentId,
    screenVariantNumber,
    screenVariantFragmentNumber,
  } = usePreparedRouteParams()

  useEffect(() => {
    setInProgressTotalAssessments(null)
  }, [screenNumber, screenVariantNumber, screenVariantFragmentNumber])

  useEffect(() => {
    if (project && heuristicsData?.heuristicsResponse) {
      const saveGeneratedReport = async () => {
        const report = await generateReport({
          project,
          heuristics: heuristicsData?.heuristicsResponse,
        })

        setReport(report)
      }

      saveGeneratedReport()
    }
  }, [project, heuristicsData?.heuristicsResponse])

  useEffect(() => {
    if (report && assessmentId) {
      document.getElementById(`assessment-${assessmentId}`)?.scrollIntoView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report])

  useEffect(() => {
    if (projectId && assessmentFilter !== 'all') {
      navigate(`/${reportPathPrefix}/${projectId}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentFilter, projectId])

  if (!report) {
    return (
      <div className={s.wrapper}>
        <ProjectHead />
      </div>
    )
  }

  const reportTotalAssessments =
    tab === 'totalAssessment'
      ? report.finalReportTotalAssessments
      : report.totalAssessments

  const totalAssessmentsCount =
    reportTotalAssessments.task +
    reportTotalAssessments.bug +
    reportTotalAssessments['ux-problem-high'] +
    reportTotalAssessments['ux-problem-medium'] +
    reportTotalAssessments['ux-problem-low'] +
    reportTotalAssessments['ux-good']

  const reportDetailsCoord: ReportDetailsCoord | null =
    screenNumber &&
    screenVariantNumber &&
    screenVariantFragmentNumber &&
    assessmentId
      ? {
          assessmentId,
          reportScreenNumber: screenNumber,
          reportScreenVariantNumber: screenVariantNumber,
          reportScreenVariantFragmentNumber: screenVariantFragmentNumber,
        }
      : null

  const encodedProjectMeta = localStorage.getItem(`metaData-${projectId}`)
  const decodedProjectMeta: RemoteProjectMeta | null = encodedProjectMeta
    ? JSON.parse(encodedProjectMeta)
    : null
  const isMobile =
    (decodedProjectMeta?.platform.includes('obile') ||
      decodedProjectMeta?.platform.includes('обил')) ??
    false

  const isAllFilter = assessmentFilter === 'all'

  const toggleInProgressAssessmentId = (
    id: string | string[],
    assessmentType: string
  ) => {
    setInProgressTotalAssessments((assessments) => {
      if (Array.isArray(id)) {
        const currentAssessments = assessments?.ids || []
        const isInProgress = id.some((item) =>
          currentAssessments.includes(item)
        )

        const newIds = isInProgress
          ? currentAssessments.filter((item) => !id.includes(item))
          : currentAssessments.concat(id)

        if (!newIds.length) return null

        return {
          ids: newIds,
          assessmentType,
        }
      }

      if (assessments?.ids.includes(id)) {
        const newIds = assessments.ids.filter((item) => item !== id)

        if (newIds.length) {
          return {
            ids: assessments.ids.filter((item) => item !== id),
            assessmentType: assessments.assessmentType,
          }
        }

        return null
      }

      if (assessments) {
        return {
          ids: assessments.ids.concat(id),
          assessmentType,
        }
      }

      return {
        ids: [id],
        assessmentType,
      }
    })
  }

  const totalAssessmentType = inProgressTotalAssessments?.assessmentType

  const isFinalReportTab = tab === 'totalAssessment'

  return (
    <div className={s.wrapper}>
      <ProjectHead />
      <div className={s.navigationAndDetailsWrapper}>
        <div className={s.navigation}>
          <div>
            {isTotalAssessment && (
              <Tabs value={tab} onChange={(_, newTab) => setTab(newTab)}>
                <Tab
                  className={s.tab}
                  value="assessment"
                  label={`${t('assessments')} (${
                    report.finalReportProgress.inProgressAssessmentsIds.length
                  })`}
                />
                <Tab
                  className={s.tab}
                  value="totalAssessment"
                  label={`${t('finalAssessment')} (${
                    report.finalReportProgress.finalReportAssessmentsIds.length
                  })`}
                />
              </Tabs>
            )}
            <ReportFilters
              filter={assessmentFilter}
              setFilter={setAssessmentFilter}
              assessments={
                isFinalReportTab
                  ? report.finalReportTotalAssessments
                  : report.totalAssessments
              }
            />
            {report.screens.map((screen) => {
              if (!isAllFilter) {
                const isProblemFilter = assessmentFilter === 'ux-problem'
                let filterCount: number

                const screenTotalAssessments = isFinalReportTab
                  ? screen.finalReportTotalAssessments
                  : screen.totalAssessments

                if (isProblemFilter) {
                  filterCount =
                    screenTotalAssessments['ux-problem-high'] +
                    screenTotalAssessments['ux-problem-low'] +
                    screenTotalAssessments['ux-problem-medium']
                } else {
                  filterCount = screenTotalAssessments[assessmentFilter]
                }

                if (!filterCount) return null
              }

              return (
                <Accordion
                  key={screen.number}
                  expanded={screenNumber === screen.number}
                  onChange={() =>
                    navigate(
                      screenNumber === screen.number
                        ? `/${reportPathPrefix}/${projectId}`
                        : `/${reportPathPrefix}/${screen.number}/${projectId}`
                    )
                  }
                  style={{
                    borderRadius: 0,
                    marginBottom: 0,
                    marginTop: '1px',
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        style={
                          screenNumber === screen.number
                            ? {
                                fill: '#2C9BF0',
                              }
                            : undefined
                        }
                      />
                    }
                    style={{
                      borderBottom: '1px solid rgba(0,0,0,0.3)',
                      flexFlow: 'row-reverse',
                    }}
                    className={s.accordionSummary}
                    classes={{
                      content: s.accordionSummaryContent,
                    }}
                  >
                    <div>
                      <Typography
                        className={s.subtitle}
                        variant="subtitle2"
                        color="gray"
                      >
                        #{screen.number}.0 {screen.actionName}
                      </Typography>
                      <Typography variant="h6" className={s.title}>
                        {screen.name}
                      </Typography>
                    </div>
                    <ReportAssessmentFilter
                      assessmentFilter={assessmentFilter}
                      assessments={
                        isFinalReportTab
                          ? screen.finalReportTotalAssessments
                          : screen.totalAssessments
                      }
                    />
                  </AccordionSummary>
                  <AccordionDetails className={s.accordionDetails}>
                    {screen.screenVariants.map((screenVariant) => {
                      if (!isAllFilter) {
                        const isProblemFilter =
                          assessmentFilter === 'ux-problem'
                        let filterCount: number

                        const screenVariantTotalAssessments = isFinalReportTab
                          ? screenVariant.finalReportTotalAssessments
                          : screenVariant.totalAssessments

                        if (isProblemFilter) {
                          filterCount =
                            screenVariantTotalAssessments['ux-problem-high'] +
                            screenVariantTotalAssessments['ux-problem-low'] +
                            screenVariantTotalAssessments['ux-problem-medium']
                        } else {
                          filterCount =
                            screenVariantTotalAssessments[assessmentFilter]
                        }

                        if (!filterCount) return null
                      }

                      if (isTotalAssessment && tab === 'assessment') {
                        const isSomeFragmentInProgress =
                          screenVariant.fragments.some((fragment) =>
                            fragment.assessments.some(
                              (assessment) =>
                                !report.finalReportProgress.finalReportRelatedAssessmentIds.includes(
                                  assessment.id
                                )
                            )
                          )

                        if (!isSomeFragmentInProgress) return null
                      }

                      return (
                        <Accordion
                          style={{
                            borderRadius: 0,
                            marginBottom: 0,
                            marginTop: '1px',
                          }}
                          className={s.coloredSection}
                          key={screenVariant.number}
                          expanded={
                            screenVariantNumber === screenVariant.number
                          }
                          onChange={() =>
                            navigate(
                              screenVariantNumber === screenVariant.number
                                ? `/${reportPathPrefix}/${screenNumber}/${projectId}`
                                : `/${reportPathPrefix}/${screenNumber}/${screenVariant.number}/${projectId}`
                            )
                          }
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                style={
                                  screenVariantNumber === screenVariant.number
                                    ? {
                                        fill: '#2C9BF0',
                                      }
                                    : undefined
                                }
                              />
                            }
                            style={{
                              borderBottom: '1px solid rgba(0,0,0,0.3)',
                              flexFlow: 'row-reverse',
                            }}
                            className={s.accordionSummary}
                            classes={{
                              content: s.accordionSummaryContent,
                            }}
                          >
                            <div>
                              <Typography
                                className={s.subtitle}
                                variant="subtitle2"
                                color="gray"
                              >
                                #{screen.number}.{screenVariant.number}{' '}
                                {screenVariant.actionName}
                              </Typography>
                              <Typography variant="h6" className={s.title}>
                                {screenVariant.name}
                              </Typography>
                            </div>
                            <ReportAssessmentFilter
                              assessmentFilter={assessmentFilter}
                              assessments={
                                isFinalReportTab
                                  ? screenVariant.finalReportTotalAssessments
                                  : screenVariant.totalAssessments
                              }
                            />
                          </AccordionSummary>
                          <AccordionDetails className={s.accordionDetails}>
                            {(isFinalReportTab
                              ? screenVariant.finalReportFragments
                              : screenVariant.fragments
                            )?.map((fragment) => {
                              return (
                                <ReportFragment
                                  screenId={screenVariant.screenId}
                                  setTab={setTab}
                                  isFinalReportTab={tab === 'totalAssessment'}
                                  assessmentFilter={assessmentFilter}
                                  setSelectedAssessmentId={(newId) => {
                                    navigate(
                                      newId
                                        ? `/${reportPathPrefix}/${screenNumber}/${screenVariantNumber}/${fragment.number}/${newId}/${projectId}`
                                        : `/${reportPathPrefix}/${screenNumber}/${screenVariantNumber}/${fragment.number}/${projectId}`
                                    )
                                  }}
                                  selectedAssessmentId={assessmentId}
                                  report={report}
                                  onExpandedChange={() =>
                                    navigate(
                                      screenVariantFragmentNumber ===
                                        fragment.number
                                        ? `/${reportPathPrefix}/${screenNumber}/${screenVariantNumber}/${projectId}`
                                        : `/${reportPathPrefix}/${screenNumber}/${screenVariantNumber}/${fragment.number}/${projectId}`
                                    )
                                  }
                                  key={fragment.number}
                                  fragment={fragment}
                                  formattedNumber={`#${screen.number}.${screenVariant.number}.${fragment.number}`}
                                  expanded={
                                    screenVariantFragmentNumber ===
                                    fragment.number
                                  }
                                  isTotalAssessment={isTotalAssessment}
                                  toggleInProgressAssessmentId={
                                    toggleInProgressAssessmentId
                                  }
                                  totalAssessmentType={totalAssessmentType}
                                  inProgressAssessmentIds={
                                    inProgressTotalAssessments?.ids
                                  }
                                  isFinalReportAssessmentsHidden={
                                    isTotalAssessment && tab === 'assessment'
                                  }
                                />
                              )
                            })}
                          </AccordionDetails>
                        </Accordion>
                      )
                    })}
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div>
        </div>

        {project && (
          <>
            {isTotalAssessment && tab === 'assessment' ? (
              <ReportAssessmentBundling
                isMobile={isMobile}
                report={report}
                project={project}
                assessmentIds={inProgressTotalAssessments?.ids}
                screenNumber={String(screenNumber)}
                screenVariantNumber={String(screenVariantNumber)}
                screenVariantFragmentNumber={String(
                  screenVariantFragmentNumber
                )}
                assessmentType={inProgressTotalAssessments?.assessmentType}
                onClearInProgressAssessments={() =>
                  setInProgressTotalAssessments(null)
                }
              />
            ) : (
              <ReportDetails
                coord={reportDetailsCoord}
                report={report}
                isMobile={isMobile}
                isFinalReport={tab === 'totalAssessment'}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}
