import {
  Box,
  Button,
  Menu,
  MenuItem,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FindSelect } from './FindSelect'
import {
  getCriticalityOptions,
  getFieldLabelByName,
  getFindTypeOptions,
} from './lib'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { defaultFindFormValues, findValidationSchema } from './form'
import AddIcon from '@mui/icons-material/Add'

import s from './FindForm.module.css'

import { ReactComponent as TrashIcon } from '../../../assets/trash.svg'

import { HeuristicSearch } from '../heuristic/search'
import { useEffect, useState } from 'react'
import { useProjectId } from 'shared/model/projects'
import {
  Assessment,
  FindType,
  findTypeAvailableFields,
  useAddAssessment,
  useDeleteAssessment,
  useEditAssessment,
  useHeuristics,
  useScreenFragments,
} from 'entities/assessment'
import { HeuristicWithButton } from './HeuristicWithButton'
import classNames from 'classnames'

interface FindFormProps {
  screenId: string
  fragmentId: string
  activeHeuristicId: string
  setActiveHeuristicId: (value: string) => void
  onListPlacement?: boolean
  assessment?: Assessment
  number?: React.ReactNode
}

const EDIT_SAVE_DELAY = 400

export const FindForm = ({
  fragmentId,
  screenId,
  setActiveHeuristicId,
  activeHeuristicId,
  assessment,
  onListPlacement = false,
  number,
}: FindFormProps) => {
  const [selectedHeuristicTags, selectHeuristicTags] = useState<string[]>([])
  const [selectedHeuristicFilter, selectHeuristicFilter] =
    useState<string>('all')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const [visibleFields, setVisibleFields] = useState<string[]>([])

  const { t } = useTranslation()

  const { register, handleSubmit, formState, setValue, watch, reset } = useForm(
    {
      resolver: yupResolver(findValidationSchema),
      defaultValues: assessment
        ? {
            criticality: assessment.type || '',
            findDescription: assessment.comment || '',
            findName: assessment.name || '',
            recommendation: assessment.recommendation || '',
            tags: assessment.tags || [],
            findType: assessment.findType || '',
            heuristicId: assessment.heuristicId || undefined,
          }
        : defaultFindFormValues,
      mode: 'all',
    }
  )

  const selectedHeuristicId = watch('heuristicId')
  const findType = watch('findType')
  const findName = watch('findName')
  const recommendation = watch('recommendation')
  const findDescription = watch('findDescription')
  const criticality = watch('criticality')
  const tags = watch('tags')

  const isEmptyHeuristicId = !assessment?.heuristicId
  const isEmptyCriticality = !assessment?.type
  const isEmptyRecommendation = !assessment?.recommendation
  const isEmptyDescription = !assessment?.comment
  const isEmptyFindName = !assessment?.name
  const isEmptyTags = !assessment?.tags || !assessment?.tags?.length

  const availableFields = findTypeAvailableFields[findType as FindType] || []

  const showHeuristicId = onListPlacement
    ? availableFields.includes('heuristicId') &&
      (!isEmptyHeuristicId || visibleFields.includes('heuristicId'))
    : availableFields.includes('heuristicId')
  const showCriticality = onListPlacement
    ? availableFields.includes('criticality') &&
      (!isEmptyCriticality || visibleFields.includes('criticality'))
    : availableFields.includes('criticality')
  const showRecommendation = onListPlacement
    ? availableFields.includes('recommendation') &&
      (!isEmptyRecommendation || visibleFields.includes('recommendation'))
    : availableFields.includes('recommendation')
  const showDescription = onListPlacement
    ? availableFields.includes('findDescription') &&
      (!isEmptyDescription || visibleFields.includes('findDescription'))
    : availableFields.includes('findDescription')
  const showFindName = onListPlacement
    ? availableFields.includes('findName') &&
      (!isEmptyFindName || visibleFields.includes('findName'))
    : availableFields.includes('findName')
  const showTags = onListPlacement
    ? availableFields.includes('tags') &&
      (!isEmptyTags || visibleFields.includes('tags'))
    : availableFields.includes('tags')

  const fieldsForAddToVisible = onListPlacement
    ? availableFields.filter((fieldName) => {
        if (fieldName === 'heuristicId') return !showHeuristicId
        if (fieldName === 'criticality') return !showCriticality
        if (fieldName === 'recommendation') return !showRecommendation
        if (fieldName === 'findDescription') return !showDescription
        if (fieldName === 'findName') return !showFindName
        if (fieldName === 'tags') return !showTags
        return false
      })
    : []

  const projectId = useProjectId()

  const heuristics = useHeuristics({
    tags: selectedHeuristicTags,
    projectId,
  })

  const fragments = useScreenFragments({ screenId })
  const fragment = fragments?.find((item) => item.id === fragmentId)
  const assessments = fragment?.assessments

  const filteredHeuristics = heuristics
    ?.filter(
      (heuristic) =>
        (selectedHeuristicFilter === 'favorite' ? heuristic.favorite : true) &&
        selectedHeuristicId !== heuristic.id &&
        assessments?.every(
          (itemAssessment) => itemAssessment.heuristicId !== heuristic.id
        ) &&
        (heuristic.evaluationArea === 'screen_and_fragment' ||
        !heuristic.evaluationArea
          ? true
          : fragment?.root
          ? heuristic.evaluationArea === 'screen'
          : heuristic.evaluationArea === 'fragment')
    )
    .sort((heuristicA) => (heuristicA.favorite ? -1 : 1))

  const addAssessment = useAddAssessment()
  const editAssessment = useEditAssessment()
  const deleteAssessment = useDeleteAssessment()

  const selectedHeuristic = heuristics?.find(
    (heuristicObject) => heuristicObject.id === selectedHeuristicId
  )

  const submitEnabled =
    (Boolean(selectedHeuristicId || findName) || !!assessment) &&
    formState.isValid

  useEffect(() => {
    if (findType === 'bug' || findType === 'task') {
      setValue('heuristicId', '')
    }
  }, [findType, setValue])

  useEffect(() => {
    if (assessment?.id) {
      const isHeuristicsEnabled =
        findType === 'ux-problem' ||
        findType === 'task' ||
        findType === 'ux-good'

      const newAssessment = {
        ...assessment,
        comment: findDescription,
        findType: findType,
        type: criticality,
        heuristicId: isHeuristicsEnabled ? selectedHeuristicId || '' : '',
        recommendation: recommendation,
        name: findName,
        tags: tags || [],
      }

      const isSameData =
        JSON.stringify(newAssessment) === JSON.stringify(assessment)

      if (!isSameData) {
        const timer = setTimeout(() => {
          editAssessment({
            assessment: {
              ...assessment,
              comment: findDescription,
              findType: findType,
              type: criticality,
              heuristicId: isHeuristicsEnabled ? selectedHeuristicId || '' : '',
              recommendation: recommendation,
              name: findName,
              tags: tags || [],
            },
            screenId,
            fragmentId,
          })
        }, EDIT_SAVE_DELAY)

        return () => clearTimeout(timer)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    criticality,
    editAssessment,
    findDescription,
    findName,
    findType,
    fragmentId,
    recommendation,
    screenId,
    selectedHeuristicId,
    tags,
  ])

  return (
    <form
      noValidate
      onSubmit={handleSubmit(
        (values) => {
          if (assessment) {
            deleteAssessment({
              assessmentId: assessment.id,
              fragmentId,
              screenId,
            })
          } else {
            const isHeuristicsEnabled =
              values.findType === 'ux-problem' ||
              values.findType === 'task' ||
              values.findType === 'ux-good'

            addAssessment({
              fragmentId,
              screenId,
              assessment: {
                comment: values.findDescription,
                findType: values.findType,
                type: values.criticality,
                heuristicId: isHeuristicsEnabled
                  ? values.heuristicId || ''
                  : '',
                recommendation: values.recommendation,
                name: values.findName,
                tags: values.tags || [],
                user: {
                  id: 'user_id',
                  fio: 'Федор Мохнатый',
                },
              },
            })
            reset()
          }
        },
        () => {
          if (assessment) {
            deleteAssessment({
              assessmentId: assessment.id,
              fragmentId,
              screenId,
            })
          }
        }
      )}
      className={classNames(s.form, {
        [s.multiple]: onListPlacement,
      })}
    >
      {onListPlacement && (
        <div className={s.numberWithDeleteButton}>
          <Typography>
            {t('find')} №{number}
          </Typography>
          <button type="submit" className={s.trashButton}>
            <TrashIcon />
          </button>
        </div>
      )}
      <FindSelect
        value={findType}
        onChange={(value) =>
          setValue('findType', value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        scrollable={onListPlacement}
        options={getFindTypeOptions()}
        error={!!formState.errors.findType}
        disabled={!!assessment}
      />
      {showHeuristicId && !selectedHeuristic && (
        <>
          <div className={s.searchAndFilter}>
            {projectId && (
              <HeuristicSearch
                selectTags={selectHeuristicTags}
                selectedTags={selectedHeuristicTags}
                placeholder={t('enterTag')}
                label={t('searchHeuristicsByTags')}
                projectId={projectId}
                selectHeuristicId={(heuristicId) =>
                  setValue('heuristicId', heuristicId, { shouldDirty: true })
                }
                heuristics={filteredHeuristics}
                isRootFragment={!!fragment?.root}
              />
            )}
          </div>
          <Box
            sx={{
              mt: 2,
              maxHeight: '160px',
              overflowY: 'auto',
              flexWrap: 'nowrap',
              mb: 2,
            }}
          >
            {filteredHeuristics?.map((heuristic) => (
              <HeuristicWithButton
                key={heuristic.id}
                negative={findType === 'ux-problem'}
                nameNegative={heuristic.nameNegative}
                favorite={heuristic.favorite}
                active={activeHeuristicId === heuristic.id}
                mode="add"
                name={heuristic.name}
                disabled={Boolean(selectedHeuristicId)}
                onTextClick={() =>
                  setActiveHeuristicId(
                    activeHeuristicId === heuristic.id ? '' : heuristic.id
                  )
                }
                onButtonClick={() =>
                  setValue('heuristicId', heuristic.id, { shouldDirty: true })
                }
              />
            ))}
          </Box>
        </>
      )}
      {selectedHeuristic && showHeuristicId && (
        <HeuristicWithButton
          active={activeHeuristicId === selectedHeuristic.id}
          favorite={selectedHeuristic.favorite}
          negative={findType === 'ux-problem'}
          nameNegative={selectedHeuristic.nameNegative}
          mode="remove"
          name={selectedHeuristic.name}
          onTextClick={() =>
            setActiveHeuristicId(
              activeHeuristicId === selectedHeuristic.id
                ? ''
                : selectedHeuristic.id
            )
          }
          onButtonClick={() =>
            setValue('heuristicId', '', { shouldDirty: true })
          }
          buttonHidden={!!assessment}
        />
      )}
      {showCriticality && (
        <FindSelect
          label={t('criticality')}
          value={watch('criticality')}
          onChange={(value) =>
            setValue('criticality', value, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }
          options={getCriticalityOptions()}
          error={!!formState.errors.criticality}
          className={onListPlacement ? '' : s.criticality}
        />
      )}
      {showFindName && (
        <TextField
          sx={{
            mb: 2,
            mt: 1,
          }}
          label={t('findName')}
          id="findName"
          size="small"
          fullWidth
          {...register('findName')}
          error={!!formState.errors.findName}
          helperText={
            selectedHeuristic && !findName ? t('findHeuristicName') : null
          }
        />
      )}
      {showDescription && (
        <TextField
          label={t('findDescription')}
          size="small"
          multiline
          rows={3}
          fullWidth
          sx={{
            mb: 2,
            mt: 1,
          }}
          {...register('findDescription')}
          error={!!formState.errors.findDescription}
        />
      )}
      {showRecommendation && (
        <TextField
          label={t('recommendation')}
          size="small"
          multiline
          rows={3}
          fullWidth
          sx={{
            mb: 3,
            mt: 1,
          }}
          {...register('recommendation')}
          error={!!formState.errors.recommendation}
        />
      )}

      {projectId && showTags && (
        <HeuristicSearch
          creatable
          selectTags={(tags) => setValue('tags', tags, { shouldDirty: true })}
          selectedTags={watch('tags') || []}
          placeholder={t('enterTag')}
          label={t('tags')}
          projectId={projectId}
          fullWidth
          onlyTags
        />
      )}

      {fieldsForAddToVisible.length > 0 && (
        <Button
          sx={{
            alignSelf: 'flex-start',
          }}
          startIcon={<AddIcon />}
          variant="text"
          onClick={handleMenuClick}
        >
          {t('add')}
        </Button>
      )}

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={(e: Event) => {
          e.stopPropagation()
          setAnchorEl(null)
        }}
      >
        {fieldsForAddToVisible.map((fieldName) => {
          return (
            <MenuItem
              onClick={() => {
                setVisibleFields((fields) => fields.concat(fieldName))
                setAnchorEl(null)
              }}
            >
              {getFieldLabelByName(fieldName, t)}
            </MenuItem>
          )
        })}
      </Menu>

      {!assessment && (
        <Button
          size="large"
          className={s.submitButton}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!submitEnabled}
          sx={{
            mt: 2,
          }}
        >
          {t('addFind')}
        </Button>
      )}
    </form>
  )
}
