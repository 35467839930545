import { Tab, Tabs, ThemeProvider, Typography } from '@mui/material'
import s from './FindSelect.module.css'
import { Option } from './types'
import { theme } from './theme'
import classNames from 'classnames'
import { FindSelectButton } from './FindSelectButton'

interface FindSelectProps {
  value: string | null
  onChange: (value: string) => void
  options: Option[]
  label?: React.ReactNode
  error: boolean
  disabled?: boolean
  className?: string
  scrollable?: boolean
}

export const FindSelect = ({
  onChange,
  options,
  value,
  label,
  error,
  disabled = false,
  className,
  scrollable,
}: FindSelectProps) => {
  const renderButtons = (isTab = false) =>
    options.map((option) => {
      const button = (
        <FindSelectButton
          icon={option.icon}
          onClick={() => onChange(option.value)}
          selected={value === option.value}
          key={option.name}
          noGrow={isTab}
          disabled={disabled}
        >
          {option.name}
        </FindSelectButton>
      )

      return isTab ? (
        <Tab
          disabled={disabled}
          className={s.tab}
          value={option.value}
          label={button}
        />
      ) : (
        button
      )
    })

  return (
    <ThemeProvider theme={theme}>
      <div className={classNames(s.wrapper, className)}>
        {!!label && (
          <Typography
            className={classNames(s.label, {
              [s.labelError]: error,
            })}
            variant="subtitle2"
          >
            {label}
          </Typography>
        )}
        {scrollable ? (
          <div className={classNames(s.inner)}>
            <Tabs
              TabIndicatorProps={{
                style: {
                  display: 'none',
                },
              }}
              className={s.tabs}
              TabScrollButtonProps={{
                style: {
                  width: '24px',
                },
              }}
              classes={{
                flexContainer: s.flexContainer,
              }}
              value={value}
              variant="scrollable"
              scrollButtons="auto"
            >
              {renderButtons(true)}
            </Tabs>
          </div>
        ) : (
          <div
            className={classNames(s.inner, {
              [s.disabled]: disabled,
            })}
          >
            <div className={s.buttons}>{renderButtons()}</div>
          </div>
        )}
      </div>
    </ThemeProvider>
  )
}
