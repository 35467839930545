import { Assessment } from 'entities/assessment'

interface IsFindDisabledParams {
  totalAssessmentType: string | undefined
  assessment: Assessment
}

export const isFindDisabled = ({
  assessment,
  totalAssessmentType,
}: IsFindDisabledParams) => {
  if (!totalAssessmentType) {
    return false
  }

  if (
    totalAssessmentType === 'ux-problem' ||
    totalAssessmentType === 'ux-good'
  ) {
    return assessment.findType === 'task' || assessment.findType === 'bug'
  }

  return assessment.findType !== totalAssessmentType
}
