import * as yup from 'yup'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Typography from '@mui/material/Typography'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { ActionDelete } from './ActionDelete/ActionDelete'
import { ActionEdit } from './ActionEdit/ActionEdit'
import {
  getActionTypeLabels,
  ActionType,
  DEFAULT_OFFSET,
  TRANSITION_ACTION_TYPES,
  TRANSITION_WIDTH,
  getNearestActionIdInColumn,
  getVisibleTransitions,
  useFlow,
  useRoles,
  useTransitionComponents,
} from 'entities/flow'
import { TransitionFormValues } from './types'
import { XYPosition } from 'reactflow'
import { EMPTY_VALUE_ID } from './config'
import i18n from 'shared/i18n/i18n'

const EXISTING_ACTION_TAB_INDEX = 0
const NEW_ACTION_TAB_INDEX = 1

const GO_TO_THE_RIGHT_TAB_INDEX = 0
const RELATED_TRANSITION_TAB_INDEX = 1

const schema = yup
  .object({
    actionTab: yup.number(),
    directionTab: yup.number(),
    actionId: yup.string().when('actionTab', {
      is: EXISTING_ACTION_TAB_INDEX,
      then: (schema) => schema.required().notOneOf([EMPTY_VALUE_ID]),
      otherwise: (schema) => schema.nullable(),
    }),
    relatedTransitionId: yup.string(),
    newAction: yup.string().when('actionTab', {
      is: NEW_ACTION_TAB_INDEX,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
    result: yup
      .string()
      .defined()
      .when('directionTab', {
        is: GO_TO_THE_RIGHT_TAB_INDEX,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
    initiator: yup
      .object({
        type: yup.string<ActionType>().required(),
        role: yup.string().defined(),
      })
      .required(),
    position: yup
      .object({
        x: yup.number().required(),
        y: yup.number().required(),
      })
      .required(),
  })
  .required()

type Props = {
  defaultValues: TransitionFormValues
  isOpen: boolean
  onClose: () => void
  onSubmit: (values: TransitionFormValues) => void
  title: string
  currentTransitionId?: string
  position: XYPosition
}

export const TransitionForm = ({
  defaultValues,
  isOpen,
  onClose,
  onSubmit,
  title,
  currentTransitionId,
  position,
}: Props) => {
  const { data: flow } = useFlow()
  const [actionForDeleteId, setActionForDeleteId] = useState<
    string | undefined
  >(undefined)
  const [actionForEditId, setActionForEditId] = useState<string | undefined>(
    undefined
  )
  const { data: components } = useTransitionComponents()
  const { data: roles } = useRoles()

  const { register, handleSubmit, watch, setValue, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
      actionTab: EXISTING_ACTION_TAB_INDEX,
      directionTab: GO_TO_THE_RIGHT_TAB_INDEX,
    },
  })

  const hasNoComponents = components?.length === 0
  const hasNoTransitionsInCurrentColumn =
    flow && position
      ? flow.transitions.every(
          (item) => item.position.x === position.x && !item.actionId
        )
      : true
  const hasNoRoles = roles?.length === 0

  const initiatorTypes = hasNoRoles
    ? TRANSITION_ACTION_TYPES.filter((type) => type !== 'role')
    : TRANSITION_ACTION_TYPES

  const rightScreen = flow?.screens.find(
    (item) =>
      item.position.y === position.y &&
      item.position.x === position.x + TRANSITION_WIDTH + DEFAULT_OFFSET
  )

  useEffect(() => {
    if (rightScreen) {
      setValue('result', rightScreen.name)
    }
  }, [rightScreen, setValue])

  useEffect(() => {
    const newInitiatorType = hasNoRoles ? 'service' : 'role'
    setValue('initiator.type', newInitiatorType)

    if (!hasNoRoles && roles?.[0]) {
      setValue('initiator.role', roles[0])
    }
  }, [hasNoRoles, setValue, roles])

  useEffect(() => {
    if (
      hasNoComponents ||
      (hasNoTransitionsInCurrentColumn && !defaultValues.actionId)
    ) {
      setValue('actionTab', NEW_ACTION_TAB_INDEX)
    } else if (
      !hasNoComponents &&
      !hasNoTransitionsInCurrentColumn &&
      position &&
      flow &&
      !defaultValues.actionId
    ) {
      const nearestActionId = getNearestActionIdInColumn({
        transitions: flow.transitions,
        position,
      })

      if (nearestActionId) {
        setValue('actionId', nearestActionId)
      } else {
        setValue('actionTab', NEW_ACTION_TAB_INDEX)
      }
    }
  }, [
    hasNoComponents,
    hasNoTransitionsInCurrentColumn,
    setValue,
    flow,
    position,
    defaultValues.actionId,
  ])

  const visibleTransitionsWithStepName = flow
    ? getVisibleTransitions({
        transitions: flow.transitions,
      })
    : []
  const selectableVisibleTransitions = currentTransitionId
    ? visibleTransitionsWithStepName.filter(
        (transition) => transition.id !== currentTransitionId
      )
    : visibleTransitionsWithStepName

  const hasTransitions = selectableVisibleTransitions.length > 0

  useEffect(() => {
    if (
      defaultValues.relatedTransitionId &&
      defaultValues.relatedTransitionId !== EMPTY_VALUE_ID &&
      hasTransitions
    ) {
      setValue('directionTab', RELATED_TRANSITION_TAB_INDEX)
    }
  }, [defaultValues.relatedTransitionId, hasTransitions, setValue])

  const selectedActionTab = watch('actionTab')
  const selectedDirectionTab = watch('directionTab')
  const isNewAction = selectedActionTab === NEW_ACTION_TAB_INDEX
  const initiatorType = watch('initiator.type')

  const actionForDelete = components?.find(
    (component) => component.id === actionForDeleteId
  )

  if (actionForDeleteId && actionForDelete) {
    return (
      <ActionDelete
        action={actionForDelete}
        onBack={() => setActionForDeleteId(undefined)}
      />
    )
  }

  const actionForEdit = components?.find(
    (component) => component.id === actionForEditId
  )

  if (actionForEditId && actionForEdit) {
    return (
      <ActionEdit
        action={actionForEdit}
        onBack={() => setActionForEditId(undefined)}
      />
    )
  }

  return (
    <Dialog disableRestoreFocus open={isOpen} onClose={onClose}>
      <form
        onSubmit={handleSubmit((values) => {
          onSubmit({
            ...values,
            initiator: {
              ...values.initiator,
              role:
                values.initiator.type === 'role'
                  ? values.initiator.role
                  : undefined,
            },
            newAction:
              values.actionTab === EXISTING_ACTION_TAB_INDEX
                ? undefined
                : values.newAction,
            actionId:
              values.actionTab === EXISTING_ACTION_TAB_INDEX
                ? values.actionId
                : undefined,
            result:
              values.directionTab === RELATED_TRANSITION_TAB_INDEX
                ? ''
                : values.result,
            relatedTransitionId:
              values.directionTab === RELATED_TRANSITION_TAB_INDEX
                ? values.relatedTransitionId
                : undefined,
          })
        })}
      >
        <DialogTitle variant="h5" fontWeight="bold">
          {title}
        </DialogTitle>
        <DialogContent
          sx={{
            minWidth: 460,
          }}
        >
          <Tabs
            value={selectedActionTab}
            onChange={(_, newSelectedActionTab) =>
              setValue('actionTab', newSelectedActionTab)
            }
          >
            <Tab label={i18n.t('existingAction')} disabled={hasNoComponents} />
            <Tab label={i18n.t('new')} />
          </Tabs>
          {isNewAction ? (
            <TextField
              fullWidth
              sx={{
                mt: 2,
                mb: 1,
              }}
              autoFocus
              label={i18n.t('whatDoesItDo')}
              error={!!formState.errors.newAction}
              {...register('newAction')}
            />
          ) : (
            <Grid
              container
              spacing={1}
              sx={{
                mt: 2,
                mb: 1,
              }}
            >
              <Grid item xs={10}>
                <FormControl fullWidth>
                  <InputLabel id="action-select">
                    {i18n.t('whatDoesItDo')}
                  </InputLabel>
                  <Select
                    labelId="action-select"
                    label={i18n.t('whatDoesItDo')}
                    {...register('actionId')}
                    value={watch('actionId') ?? EMPTY_VALUE_ID}
                    error={!!formState.errors.actionId}
                  >
                    {components?.map((component) => (
                      <MenuItem key={component.id} value={component.id}>
                        {component.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  onClick={() => setActionForEditId(watch('actionId'))}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  onClick={() => setActionForDeleteId(watch('actionId'))}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={initiatorType === 'role' ? 6 : 12}>
              <FormControl
                fullWidth
                sx={{
                  marginBottom: 1,
                  mt: 1,
                }}
              >
                <InputLabel id="initiator-type-select">
                  {i18n.t('whatIsTheInitiator')}
                </InputLabel>
                <Select
                  labelId="initiator-type-select"
                  label={i18n.t('whatIsTheInitiator')}
                  {...register('initiator.type')}
                  value={initiatorType || ''}
                  error={!!formState.errors.initiator?.type}
                >
                  {initiatorTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {getActionTypeLabels()[type]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {initiatorType === 'role' && !!watch('initiator.role') && (
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  sx={{
                    marginBottom: 1,
                    mt: 1,
                  }}
                >
                  <InputLabel id="initiator-role-select">
                    {i18n.t('role')}
                  </InputLabel>
                  <Select
                    labelId="initiator-role-select"
                    label={i18n.t('role')}
                    {...register('initiator.role')}
                    value={watch('initiator.role')}
                    error={!!formState.errors.initiator?.role}
                  >
                    {roles?.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{
              mt: 2,
            }}
          >
            {i18n.t('whereDoesItGo')}
          </Typography>
          <Tabs
            value={selectedDirectionTab}
            onChange={(_, newSelectedDirectionTab) =>
              setValue('directionTab', newSelectedDirectionTab)
            }
            sx={{
              mb: 1,
            }}
          >
            <Tab label={i18n.t('furtherToTheRight')} />
            <Tab
              label={i18n.t('somewhereElse')}
              disabled={selectableVisibleTransitions.length === 0}
            />
          </Tabs>
          {selectedDirectionTab === GO_TO_THE_RIGHT_TAB_INDEX ? (
            <TextField
              fullWidth
              sx={{
                mt: 1,
              }}
              autoFocus
              label={i18n.t('whatDoYouGetAsAResult')}
              error={!!formState.errors.result}
              {...register('result')}
            />
          ) : (
            <FormControl
              fullWidth
              sx={{
                marginBottom: 1,
                mt: 1,
              }}
            >
              <InputLabel id="relatedTransition-select">
                {i18n.t('relatedTransition')}
              </InputLabel>
              <Select
                labelId="relatedTransition-select"
                label={i18n.t('relatedTransition')}
                {...register('relatedTransitionId')}
                value={watch('relatedTransitionId') ?? EMPTY_VALUE_ID}
                error={!!formState.errors.relatedTransitionId}
              >
                {selectableVisibleTransitions?.map((transition) => (
                  <MenuItem key={transition.id} value={transition.id}>
                    {/* {transition.step?.name ? `${transition.step.name}:` : ''} */}
                    {transition.result}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{i18n.t('cancel')}</Button>
          <Button type="submit" variant="contained">
            {i18n.t('save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
