import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  TransitionComponent,
  useDeleteTransitionComponentAndClearThisFromTransitions,
  useFlow,
} from 'entities/flow'
import i18n from 'shared/i18n/i18n'

type Props = {
  action: TransitionComponent
  onBack: VoidFunction
}

export const ActionDelete = ({ action, onBack }: Props) => {
  const { data } = useFlow()
  const transitionWithThisAction =
    data?.transitions.filter(
      (transition) => transition.actionId === action.id
    ) || []

  const deleteTransitionComponentAndClearThisFromTransitions =
    useDeleteTransitionComponentAndClearThisFromTransitions()

  const handleDelete = () => {
    deleteTransitionComponentAndClearThisFromTransitions({
      transitionIds: transitionWithThisAction.map(
        (transition) => transition.id
      ),
      componentId: action.id,
      onSuccess: onBack,
    })
  }

  return (
    <Dialog disableRestoreFocus open={true} onClose={onBack}>
      <DialogTitle variant="h5" fontWeight="bold">
        <Box display="flex" alignItems="center" mb={1}>
          <Button startIcon={<ArrowBackIcon />} onClick={onBack} variant="text">
            {i18n.t('back')}
          </Button>
        </Box>
        {i18n.t('actionRemoving')}
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: 460,
        }}
      >
        <Typography>
          {i18n.t('areYouSureToDeleteTheAction')} "{action.name}"?
          <br />
          {i18n.t('itIsListedInTransitions', {
            number: transitionWithThisAction.length,
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete} variant="contained">
          {i18n.t('remove')}
        </Button>
        <Button onClick={onBack}>{i18n.t('cancel')}</Button>
      </DialogActions>
    </Dialog>
  )
}
