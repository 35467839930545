import { TransitionForm } from '../TransitionForm'
import { Transition, useTransitionEdit } from 'entities/flow'
import { TransitionFormValues } from '../types'
import i18n from 'shared/i18n/i18n'

type Props = {
  isOpen: boolean
  onClose: () => void
  transition: Transition
}

export const TransitionEdit = ({ isOpen, onClose, transition }: Props) => {
  const transitionEdit = useTransitionEdit()

  const onSubmit = (values: TransitionFormValues) => {
    transitionEdit({
      transition,
      values,
      onSuccess: onClose,
    })
  }

  return (
    <TransitionForm
      isOpen={isOpen}
      onClose={onClose}
      title={i18n.t('transitionEditing')}
      onSubmit={onSubmit}
      defaultValues={{
        ...transition,
        newAction: '',
      }}
      currentTransitionId={transition.id}
      position={transition.position}
    />
  )
}
