import s from './AssessmentFragment.module.css'
import {
  ScreenFragment,
  useDeleteScreenFragment,
  useUpdateScreenFragmentName,
} from 'entities/assessment'
import classNames from 'classnames'
import { useState } from 'react'
import { useHasWritePermission } from 'entities/permissions'
import { ReportAssessmentType } from 'widgets/report/types'
import { ReactComponent as TrashIcon } from '../../../../assets/trash.svg'
import { ReactComponent as UserPenIcon } from '../../../../assets/user-pen.svg'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { AssessmentDigit } from './AssessmentDigit'

interface AssessmentFragmentProps {
  fragment: ScreenFragment | null
  screenId: string
  onClick: VoidFunction
  clickable: boolean
  inputEditable: boolean
  deletable: boolean
  name?: string
}

const renderAssessment = (count: number, type: ReportAssessmentType) => (
  <AssessmentDigit count={count} type={type} />
)

export const AssessmentFragment = ({
  fragment,
  screenId,
  onClick,
  inputEditable,
  clickable,
  deletable,
  name,
}: AssessmentFragmentProps) => {
  const { t } = useTranslation()

  const [fragmentName, setFragmentName] = useState<string>(fragment?.name || '')

  const goodUxAssessmentsCount =
    fragment?.assessments?.filter((item) => item.findType === 'ux-good')
      .length ?? 0
  const problemUxLowAssessmentsCount =
    fragment?.assessments?.filter(
      (item) => item.findType === 'ux-problem' && item.type === 'good'
    ).length ?? 0
  const problemUxMediumAssessmentsCount =
    fragment?.assessments?.filter(
      (item) => item.findType === 'ux-problem' && item.type === 'medium'
    ).length ?? 0
  const problemUxHighAssessmentsCount =
    fragment?.assessments?.filter(
      (item) => item.findType === 'ux-problem' && item.type === 'bad'
    ).length ?? 0
  const taskAssessmentsCount =
    fragment?.assessments?.filter((item) => item.findType === 'task').length ??
    0
  const bugAssessmentsCount =
    fragment?.assessments?.filter((item) => item.findType === 'bug').length ?? 0

  const updateScreenFragmentName = useUpdateScreenFragmentName()

  const hasWritePermission = useHasWritePermission()

  const updateFragmentName = () =>
    hasWritePermission
      ? updateScreenFragmentName({
          fragmentId: fragment?.id || '',
          screenId,
          name: fragmentName,
        })
      : undefined

  const deleteScreenFragment = useDeleteScreenFragment()

  return (
    <div
      onClick={clickable ? onClick : undefined}
      className={classNames(s.header, {
        [s.clickable]: clickable,
      })}
    >
      {fragment && (
        <div>
          <input
            className={classNames(s.input, {
              [s.inputReadonly]: !inputEditable,
            })}
            onBlur={updateFragmentName}
            value={name || fragmentName}
            onChange={
              !inputEditable
                ? undefined
                : (e) => setFragmentName(e.target.value)
            }
            onKeyDown={(e) => {
              if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                e.currentTarget.blur()
              }
            }}
            minLength={1}
            onClick={(e) => {
              e.stopPropagation()
            }}
          />
          {fragment.isCustom && (
            <div className={s.customFragmentWrapper}>
              <UserPenIcon />
              <Typography className={s.customFragmentText}>
                {t('your')}
              </Typography>
            </div>
          )}
        </div>
      )}
      <div className={s.assessments}>
        {renderAssessment(goodUxAssessmentsCount, 'ux-good')}
        {renderAssessment(problemUxLowAssessmentsCount, 'ux-problem-low')}
        {renderAssessment(problemUxMediumAssessmentsCount, 'ux-problem-medium')}
        {renderAssessment(problemUxHighAssessmentsCount, 'ux-problem-high')}
        {renderAssessment(taskAssessmentsCount, 'task')}
        {renderAssessment(bugAssessmentsCount, 'bug')}
      </div>
      {deletable && (
        <button
          className={s.deleteIconButton}
          onClick={(e) => {
            e.stopPropagation()
            deleteScreenFragment({
              fragmentId: fragment?.id || '',
              onSuccess: () => null,
              screenId,
            })
          }}
        >
          <TrashIcon />
        </button>
      )}
    </div>
  )
}
