import { XYPosition } from 'reactflow'
import PersonIcon from '@mui/icons-material/Person'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { ActionType } from './types'
import i18n from 'shared/i18n/i18n'

export const DEFAULT_OFFSET = 50
export const SCREEN_WIDTH = 300
export const TRANSITION_WIDTH = 450
export const PAIR_WIDTH = SCREEN_WIDTH + DEFAULT_OFFSET + TRANSITION_WIDTH
export const BASIC_HEIGHT = 200
export const SCREEN_HEIGHT = BASIC_HEIGHT
export const TRANSITION_HEIGHT = BASIC_HEIGHT

export const INITIAL_POSITION: XYPosition = {
  x: 0,
  y: 0,
}

export const TRANSITION_ACTION_TYPES: ActionType[] = [
  'role',
  'time',
  'service',
  'context',
]

export const getActionTypeLabels = (): Record<ActionType, string> => ({
  role: i18n.t('user'),
  service: i18n.t('service'),
  time: i18n.t('time'),
  context: i18n.t('context'),
})

export const ACTION_TYPE_ICONS: Record<ActionType, JSX.Element> = {
  role: <PersonIcon />,
  service: <MiscellaneousServicesIcon />,
  context: <MiscellaneousServicesIcon />,
  time: <HourglassEmptyIcon />,
}
