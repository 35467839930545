import { Report } from '../types'
import classNames from 'classnames'
import { IconButton, Typography } from '@mui/material'
import { isEnglishProject } from 'shared/lib/language'
import { Assessment } from 'entities/assessment'
import PestControlIcon from '@mui/icons-material/PestControl'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import s from './ReportFind.module.css'

interface ReportFindProps {
  projectId: string
  heuristics: Report['heuristics']
  assessment: Assessment
  active: boolean
  onClick: VoidFunction
  onWholePlateClick: VoidFunction
  isTotalAssessment?: boolean
  disabled?: boolean
  inProgressTotalAssessment?: boolean
  dashedLine?: 'top' | 'top-bottom' | 'bottom'
  excluded?: boolean
  isFinalReportTab?: boolean
}

export const ReportFind = ({
  heuristics,
  active,
  assessment,
  onClick,
  projectId,
  isTotalAssessment = false,
  disabled = false,
  inProgressTotalAssessment = false,
  dashedLine,
  excluded = false,
  isFinalReportTab = false,
  onWholePlateClick,
}: ReportFindProps) => {
  if (!assessment.id) {
    return null
  }

  const isEnglish = isEnglishProject(projectId)

  const heuristic = heuristics.find(
    (item) => item.id === assessment.heuristicId
  )

  const negativeName = isEnglish
    ? heuristic?.field_negative_name_en
    : heuristic?.field_negative_name_ru

  const thirdLevelTitle = isEnglish
    ? heuristic?.field_heuristic_3_level_en
    : heuristic?.title

  const heuristicName =
    assessment.findType === 'ux-problem' ? negativeName : thirdLevelTitle

  const name = assessment.name || heuristicName

  const renderIcon = () => {
    const hasHeuristicAndHasNoGroup =
      !!assessment.heuristicId && !assessment.hasHeuristicGroup

    if (assessment.findType === 'bug') {
      return (
        <div
          className={classNames(s.iconWrapper, {
            [s.dottedIcon]: hasHeuristicAndHasNoGroup,
          })}
        >
          <PestControlIcon className={classNames(s.icon)} />
        </div>
      )
    }

    if (assessment.findType === 'task') {
      return (
        <div
          className={classNames(s.iconWrapper, {
            [s.dottedIcon]: hasHeuristicAndHasNoGroup,
          })}
        >
          <LightbulbIcon className={classNames(s.icon)} />
          <div
            className={classNames(s.dashedLine, {
              [s.dashedBottomLine]:
                dashedLine === 'bottom' || dashedLine === 'top-bottom',
              [s.dashedTopLine]:
                dashedLine === 'top' || dashedLine === 'top-bottom',
            })}
          />
        </div>
      )
    }

    return (
      <div
        className={classNames(s.circle, {
          [s.good]: assessment.findType === 'ux-good',
          [s.medium]:
            assessment.findType === 'ux-problem' &&
            assessment.type === 'medium',
          [s.bad]:
            assessment.findType === 'ux-problem' && assessment.type === 'bad',
          [s.low]:
            assessment.findType === 'ux-problem' && assessment.type === 'good',
          [s.dottedIcon]: hasHeuristicAndHasNoGroup,
        })}
      >
        <div
          className={classNames(s.dashedLine, {
            [s.dashedBottomLine]:
              dashedLine === 'bottom' || dashedLine === 'top-bottom',
            [s.dashedTopLine]:
              dashedLine === 'top' || dashedLine === 'top-bottom',
          })}
        />
      </div>
    )
  }

  const renderButtonIcon = () => {
    if (isFinalReportTab) {
      return <ArrowBackIcon fontSize="large" />
    }
    if (inProgressTotalAssessment) {
      return <RemoveIcon fontSize="large" />
    }
    return <AddIcon fontSize="large" />
  }

  return (
    <div
      onClick={isTotalAssessment ? onWholePlateClick : onClick}
      className={classNames(s.reportHeuristic, {
        [s.active]: active,
      })}
      id={`assessment-${assessment.id}`}
    >
      {isTotalAssessment && (
        <IconButton
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation()
            onClick()
          }}
          className={classNames(s.button)}
        >
          {renderButtonIcon()}
        </IconButton>
      )}
      {renderIcon()}
      <div>
        {!!assessment.user?.fio && (
          <Typography variant="subtitle2" className={s.subtitle}>
            {assessment.user.fio}
          </Typography>
        )}
        <Typography
          variant="h6"
          className={s.title}
          fontWeight={active ? 'bold' : 'normal'}
        >
          {name}
        </Typography>
      </div>
      {excluded && (
        <DoNotDisturbIcon
          classes={{
            root: classNames(s.excludedIcon, {
              [s.disabledIcon]: disabled,
            }),
          }}
        />
      )}
    </div>
  )
}
