import { createUUID } from 'shared/lib/uuid'
import { FlowElement, Transition, TransitionComponent } from './types'
import { XYPosition } from 'reactflow'
import { DEFAULT_OFFSET } from './config'

export const getNewTransitionComponent = (
  name: string
): TransitionComponent => ({
  id: createUUID(),
  name,
})

interface GetSortedByPositionElementsParams<T extends FlowElement> {
  axis: 'x' | 'y'
  elements: T[]
}

export const getSortedByPositionElements = <T extends FlowElement>({
  axis,
  elements,
}: GetSortedByPositionElementsParams<T>): T[] =>
  elements.sort((elementA, elementB) => {
    return elementB.position[axis] - elementA.position[axis]
  })

interface GetSortedByPositionInRowElementsParams<T extends FlowElement> {
  elements: T[]
  positionY: number
}

export const getSortedByPositionInRowElements = <T extends FlowElement>({
  positionY,
  elements,
}: GetSortedByPositionInRowElementsParams<T>): T[] =>
  getSortedByPositionElements({
    elements: elements.filter((element) => element.position.y === positionY),
    axis: 'x',
  })

interface GetSortedByPositionInColumnElementsParams<T extends FlowElement> {
  elements: T[]
  positionX: number
}

export const getSortedByPositionInColumnElements = <T extends FlowElement>({
  positionX,
  elements,
}: GetSortedByPositionInColumnElementsParams<T>) =>
  getSortedByPositionElements({
    elements: elements.filter((element) => element.position.x === positionX),
    axis: 'y',
  })

interface GetNearestRightFlowElementParams<T> {
  elements: T[]
  position: XYPosition
}

export const getNearestRightFlowElement = <T extends FlowElement>({
  elements,
  position,
}: GetNearestRightFlowElementParams<T>): T | undefined => {
  const sortedRowElements = getSortedByPositionInRowElements({
    positionY: position.y,
    elements,
  })
  const nearestRightElement = sortedRowElements.find(
    (element) => element.position.x > position.x
  )

  return nearestRightElement
}

interface GetNearestActionIdInColumnParams {
  position: XYPosition
  transitions: Transition[]
}

export const getNearestActionIdInColumn = ({
  position,
  transitions,
}: GetNearestActionIdInColumnParams) => {
  const sortedColumnTransitions = getSortedByPositionInColumnElements({
    positionX: position.x,
    elements: transitions,
  })

  const topTransitions = sortedColumnTransitions.filter(
    (transition) => transition.position.y < position.y
  )

  for (let i = 0; i < topTransitions.length; i++) {
    const index = topTransitions.length - 1 - i
    const transition = topTransitions[index]
    const actionId = transition.actionId

    if (actionId) {
      return actionId
    }
  }

  const bottomTransitions = sortedColumnTransitions.filter(
    (transition) => transition.position.y > position.y
  )

  for (let i = 0; i < bottomTransitions.length; i++) {
    const index = i
    const transition = bottomTransitions[index]
    const actionId = transition.actionId

    if (actionId) {
      return actionId
    }
  }

  return undefined
}

interface GetVisibleTransitionsParams {
  transitions: Transition[]
}

export const getVisibleTransitions = ({
  transitions,
}: GetVisibleTransitionsParams) => transitions.filter((item) => item.actionId)
