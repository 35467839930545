import { axiosInstance } from './axios'

interface UploadImageAndLinkWithProjectParams {
  file: File
  projectId: string
  onError: (message: string) => void
}

interface UploadImageResponse {
  data: {
    id: string
    attributes: {
      uri: {
        url: string
      }
    }
  }
}

export const uploadImageAndLinkWithProject = async ({
  file,
  projectId,
  onError,
}: UploadImageAndLinkWithProjectParams) => {
  const fileBuffer = await file.arrayBuffer()

  try {
    const uploadResponse = await axiosInstance.post<UploadImageResponse>(
      'https://backend.prodmaps.com/api135a/node/project/field_project_images',
      fileBuffer,
      {
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/octet-stream',
          'Content-Disposition': `file; filename="${encodeURIComponent(
            file.name
          )}"`,
        },
      }
    )

    const imageId = uploadResponse.data.data.id
    const imageRelativeUrl = uploadResponse.data.data.attributes.uri.url

    try {
      await axiosInstance.post(
        `https://backend.prodmaps.com/api135a/node/project/${projectId}/relationships/field_project_images`,
        {
          data: [
            {
              type: 'file--file',
              id: imageId,
              meta: {
                description: file.name,
              },
            },
          ],
        },
        {
          headers: {
            'Content-Type': 'application/vnd.api+json',
            Accept: 'application/vnd.api+json',
          },
        }
      )

      return {
        imageUrl: `https://backend.prodmaps.com${imageRelativeUrl}`,
      }
    } catch (e) {
      onError('Произошла ошибка при связывании изображения и проекта')

      throw e
    }
  } catch (e) {
    onError('Произошла ошибка при загрузке изображения на сервер')

    throw e
  }
}
