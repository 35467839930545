import { Typography } from '@mui/material'
import classNames from 'classnames'
import s from './AssessmentFragmentTitle.module.css'
import { getReportAssessmentAttributes } from 'entities/assessment'

interface AssessmentFragmentTitleProps {
  title: string
  className?: string
}

export const AssessmentFragmentTitle = ({
  title,
  className,
}: AssessmentFragmentTitleProps) => {
  const assessmentAttributes = getReportAssessmentAttributes()

  return (
    <div className={classNames(s.wrapper, className)}>
      <Typography
        classes={{
          root: s.title,
        }}
      >
        {title}
      </Typography>
      <div className={s.icons}>
        <div className={s.iconWrapper}>
          {assessmentAttributes['ux-good'].icon}
        </div>
        <div className={s.iconWrapper}>
          {assessmentAttributes['ux-problem-low'].icon}
        </div>
        <div className={s.iconWrapper}>
          {assessmentAttributes['ux-problem-medium'].icon}
        </div>
        <div className={s.iconWrapper}>
          {assessmentAttributes['ux-problem-high'].icon}
        </div>
        <div className={s.iconWrapper}>{assessmentAttributes['task'].icon}</div>
        <div className={s.iconWrapper}>{assessmentAttributes['bug'].icon}</div>
      </div>
    </div>
  )
}
