import { Dialog, DialogTitle, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import s from './ExamplePopup.module.css'
import classNames from 'classnames'

interface ExamplePopupProps {
  type: 'image' | 'video'
  url: string
  name: string
  open: boolean
  onClose: VoidFunction
  description: string
  good: boolean
  poster?: string
}

export const ExamplePopup = ({
  url,
  type,
  name,
  onClose,
  open,
  description,
  poster,
  good,
}: ExamplePopupProps) => {
  const renderContent = () => {
    if (!url) {
      return <div>URL source error</div>
    }

    const icon = good ? (
      <ThumbUpIcon className={classNames(s.good, s.recommendationIcon)} />
    ) : (
      <ThumbDownIcon className={classNames(s.bad, s.recommendationIcon)} />
    )

    const descriptionNode = (
      <div className={s.descriptionWrapper}>
        {icon}
        <Typography>{description}</Typography>
      </div>
    )

    if (type === 'video') {
      return (
        <>
          <video
            key={url}
            style={{
              maxHeight: '750px',
            }}
            controls
            poster={poster}
          >
            <source
              src={`https://backend.prodmaps.com${url}`}
              type="video/mp4"
            />
            Your browser doesn't support HTML5 video tag.
          </video>
          {descriptionNode}
        </>
      )
    }

    return (
      <>
        <img
          alt={name}
          style={{
            maxHeight: '750px',
          }}
          src={`https://backend.prodmaps.com${url}`}
        />
        {descriptionNode}
      </>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      classes={{
        paper: s.popup,
      }}
      onClose={onClose}
    >
      <DialogTitle>{name}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {renderContent()}
    </Dialog>
  )
}
