import * as yup from 'yup'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { TransitionComponent, useEditTransitionComponent } from 'entities/flow'
import i18n from 'shared/i18n/i18n'

type Props = {
  action: TransitionComponent
  onBack: VoidFunction
}

type FormValues = Omit<TransitionComponent, 'id'>

const schema: yup.ObjectSchema<FormValues> = yup.object({
  name: yup.string().required(),
})

export const ActionEdit = ({ action, onBack }: Props) => {
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: action,
  })

  const editTransitionComponent = useEditTransitionComponent()

  const handleEdit = (values: FormValues) => {
    editTransitionComponent({
      component: {
        ...action,
        name: values.name,
      },
      onSuccess: onBack,
    })
  }

  return (
    <Dialog disableRestoreFocus open={true} onClose={onBack}>
      <form onSubmit={handleSubmit(handleEdit)}>
        <DialogTitle variant="h5" fontWeight="bold">
          <Box display="flex" alignItems="center" mb={1}>
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={onBack}
              variant="text"
            >
              {i18n.t('back')}
            </Button>
          </Box>
          {i18n.t('actionEditing')}
          <br />
          <Typography variant="body1" fontStyle="italic" color="gray">
            {action.name}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            minWidth: 460,
          }}
        >
          <TextField
            fullWidth
            sx={{
              mt: 2,
              mb: 1,
            }}
            autoFocus
            label={i18n.t('whatDoesItDo')}
            error={!!formState.errors.name}
            {...register('name')}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained">
            {i18n.t('save')}
          </Button>
          <Button onClick={onBack}>{i18n.t('cancel')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
