/* eslint-disable no-loop-func */
import { useSnackbar } from 'notistack'
import { QueryKey } from '@tanstack/react-query'
import {
  Flow,
  FlowElement,
  FlowNode,
  Screen,
  Transition,
  TransitionComponent,
} from './types'
import { Edge, MarkerType, XYPosition } from 'reactflow'
import { createUUID } from 'shared/lib/uuid'
import {
  BASIC_HEIGHT,
  DEFAULT_OFFSET,
  PAIR_WIDTH,
  SCREEN_WIDTH,
  TRANSITION_HEIGHT,
  TRANSITION_WIDTH,
} from './config'
import { useProject, useUpdateProjectMutation } from 'shared/model/projects'
import { getInitialScreenFragments } from 'entities/assessment'
import { TransitionFormValues } from 'features/flow/transition/TransitionForm/types'
import { getNewTransitionComponent } from './lib'
import { ProjectData } from 'shared/types/projects'
import { EMPTY_VALUE_ID } from 'features/flow/transition/TransitionForm/config'
import { uploadImageAndLinkWithProject } from 'shared/api/upload-image'
import i18n from 'shared/i18n/i18n'

export const FLOW_KEY: QueryKey = ['flow']
export const TRANSITION_COMPONENT_KEY: QueryKey = ['transition-components']
export const ROLES_KEY: QueryKey = ['roles']

export const useFlow = () => {
  const { data: projectData } = useProject()

  return {
    data: projectData?.flow,
  }
}

const getProjectDataWithActualTransitionComponents = (
  projectData: ProjectData
): ProjectData => {
  const actualTransitionComponents = projectData.transitionComponents.filter(
    (transitionComponent) => {
      const isUsedInTransitions = projectData.flow.transitions.some(
        (transition) => transition.actionId === transitionComponent.id
      )
      return isUsedInTransitions
    }
  )

  return {
    ...projectData,
    transitionComponents: actualTransitionComponents,
  }
}

interface CreateScreenParams {
  name: string
  position: XYPosition
  previewImageUrl?: string
  imageFileName?: string
  first: 'screen' | 'transition'
}

export const createScreen = ({
  name,
  position,
  previewImageUrl,
  imageFileName,
}: Omit<CreateScreenParams, 'first'>) => {
  const newScreen: Screen = {
    id: createUUID(),
    fullImageUrl: previewImageUrl ?? undefined,
    previewImageUrl: previewImageUrl ?? undefined,
    name,
    position: position,
    imageFileName,
  }

  return newScreen
}

const createScreenWithTransition = ({
  name,
  position,
  previewImageUrl,
  first,
  imageFileName,
}: CreateScreenParams) => {
  const screenPosition: XYPosition =
    first === 'screen'
      ? position
      : {
          ...position,
          x: position.x + DEFAULT_OFFSET + TRANSITION_WIDTH,
        }
  const newScreen = createScreen({
    name,
    previewImageUrl,
    position: screenPosition,
    imageFileName,
  })

  const transitionPosition: XYPosition =
    first === 'transition'
      ? position
      : {
          ...position,
          x: position.x + DEFAULT_OFFSET + SCREEN_WIDTH,
        }
  const newTransition: Transition = {
    id: createUUID(),
    actionId: undefined,
    result: '',
    initiator: undefined,
    position: transitionPosition,
    relatedTransitionId: undefined,
  }

  return {
    screen: newScreen,
    transition: newTransition,
  }
}

interface UpdateImageOnScreenParams {
  file: File
  screenId: string
}

export const useUpdateImageOnScreen = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()
  const { enqueueSnackbar } = useSnackbar()

  return async ({ file, screenId }: UpdateImageOnScreenParams) => {
    if (projectData) {
      const { imageUrl } = await uploadImageAndLinkWithProject({
        file,
        projectId: projectData.id,
        onError: (message) => enqueueSnackbar(message, { variant: 'error' }),
      })

      const newProjectData: ProjectData = {
        ...projectData,
        flow: {
          ...projectData.flow,
          screens: projectData.flow.screens.map((screen) => {
            if (screen.id !== screenId) return screen

            return {
              ...screen,
              name: screen.name || file.name,
              imageFileName: file.name,
              previewImageUrl: imageUrl,
            }
          }),
        },
      }

      mutateAsync(newProjectData)
    }
  }
}

interface CreateScreensParams {
  files: File[]
  position: XYPosition
  firstScreenId: string
}

export const useAddScreensFromFiles = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()
  const { enqueueSnackbar } = useSnackbar()

  return async ({
    files,
    position: startPosition,
    firstScreenId,
  }: CreateScreensParams) => {
    if (projectData) {
      const newScreens: Screen[] = []
      const newTransitions: Transition[] = []
      const newImageUrls: Record<number, string> = {}

      const promises = files.map(async (file, index) => {
        const { imageUrl } = await uploadImageAndLinkWithProject({
          file,
          projectId: projectData.id,
          onError: (message) => enqueueSnackbar(message, { variant: 'error' }),
        })

        const deltaX =
          index > 1
            ? SCREEN_WIDTH +
              DEFAULT_OFFSET +
              (index - 1) * (PAIR_WIDTH + DEFAULT_OFFSET)
            : (SCREEN_WIDTH + DEFAULT_OFFSET) * index

        const position: XYPosition = {
          y: startPosition.y,
          x: startPosition.x + deltaX,
        }

        const { screen, transition } = createScreenWithTransition({
          name: file.name,
          position,
          previewImageUrl: imageUrl,
          imageFileName: file.name,
          first: 'transition',
        })

        const isFirstFile = index === 0

        if (!isFirstFile) {
          newScreens.push(screen)
          newTransitions.push(transition)
        }

        newImageUrls[index] = imageUrl
      })

      await Promise.all(promises)

      const newScreenFragments = {
        ...projectData.screenFragments,
      }

      newScreens.forEach((newScreen) => {
        newScreenFragments[newScreen.id] = getInitialScreenFragments()
      })

      const newProjectData: ProjectData = {
        ...projectData,
        flow: {
          ...projectData.flow,
          screens: projectData.flow.screens
            .map((screen) => {
              if (screen.id !== firstScreenId) return screen

              return {
                ...screen,
                name: screen.name || files[0].name,
                imageFileName: files[0].name,
                previewImageUrl: newImageUrls[0],
              }
            })
            .map(
              (element) =>
                shiftToRightWithPairMap({
                  element,
                  fromPositionX: startPosition.x,
                  shiftCount: newScreens.length,
                  forOnlyPositionY: startPosition.y,
                }) as any
            )
            .concat(newScreens),
          transitions: projectData.flow.transitions
            .map(
              (element) =>
                shiftToRightWithPairMap({
                  element,
                  fromPositionX: startPosition.x,
                  shiftCount: newTransitions.length,
                  forOnlyPositionY: startPosition.y,
                }) as any
            )
            .concat(newTransitions),
        },
        screenFragments: newScreenFragments,
      }

      mutateAsync(newProjectData)
    }
  }
}

interface UpdateScreenNameParams {
  id: string
  name: string
}

export const useUpdateScreenName = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({ id, name }: UpdateScreenNameParams) => {
    if (projectData) {
      mutateAsync({
        ...projectData,
        flow: {
          ...projectData.flow,
          screens: projectData.flow.screens.map((screen) => {
            if (screen.id !== id) return screen

            return {
              ...screen,
              name,
            }
          }),
        },
      })
    }
  }
}

export const useFlowNodes = (): FlowNode[] => {
  const { data } = useFlow()

  if (!data) {
    return []
  }

  const screenNodes: FlowNode[] = data.screens.map((screen) => ({
    id: screen.id,
    data: {
      type: 'screen',
      name: screen.name,
      previewImageUrl: screen.previewImageUrl,
    },
    position: screen.position,
    draggable: false,
    type: 'screen',
  }))

  const transitionNodes: FlowNode[] = data.transitions.map((transition) => ({
    id: transition.id,
    position: transition.position,
    data: {
      ...transition,
      type: 'transition',
    },
    draggable: false,
    type: 'transition',
  }))

  const xTransitionCoords = transitionNodes.map((item) => item.position.x)
  const uniqXTransitionCoords = [...new Set(xTransitionCoords)]
  const transitionsAndScreens = [...screenNodes, ...transitionNodes]
  const yCoords = transitionsAndScreens.map((item) => item.position.y)
  const uniqYCoords = [...new Set(yCoords)]

  const leftBorderX = Math.min(...uniqXTransitionCoords)
  const topBorderY = Math.min(...uniqYCoords)

  const width = Math.max(...uniqXTransitionCoords) - leftBorderX
  const height = Math.max(...uniqYCoords) - topBorderY

  let containers: FlowNode[] = []

  uniqXTransitionCoords.forEach((xCoord) => {
    containers.push({
      id: createUUID(),
      type: 'container',
      data: {
        type: 'container',
        subType: 'column',
      },
      position: {
        x: xCoord,
        y: -3 * DEFAULT_OFFSET,
      },
      style: {
        width: PAIR_WIDTH,
        height: height + 6 * DEFAULT_OFFSET + BASIC_HEIGHT,
      },
      draggable: false,
      focusable: false,
    })
  })

  uniqYCoords.forEach((yCoord) => {
    containers.push({
      id: createUUID(),
      type: 'container',
      data: {
        type: 'container',
        subType: 'row',
      },
      position: {
        x: leftBorderX - 3 * DEFAULT_OFFSET,
        y: yCoord,
      },
      style: {
        width: width + 6 * DEFAULT_OFFSET + PAIR_WIDTH,
        height: BASIC_HEIGHT,
      },
      draggable: false,
      focusable: false,
    })
  })

  return [...containers, ...screenNodes, ...transitionNodes]
}

interface DeleteTransitionComponentAndClearThisFromTransitionsParams {
  transitionIds: string[]
  componentId: string
  onSuccess: VoidFunction
}

export const useDeleteTransitionComponentAndClearThisFromTransitions = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({
    componentId,
    onSuccess,
    transitionIds,
  }: DeleteTransitionComponentAndClearThisFromTransitionsParams) => {
    if (projectData) {
      mutateAsync(
        {
          ...projectData,
          flow: {
            ...projectData.flow,
            transitions: projectData.flow.transitions.map((item) => {
              if (transitionIds.includes(item.id)) {
                return {
                  ...item,
                  actionId: undefined,
                }
              }
              return item
            }),
          },
          transitionComponents: projectData.transitionComponents.filter(
            (component) => component.id !== componentId
          ),
        },
        { onSuccess }
      )
    }
  }
}

export const useTransitionComponents = () => {
  const { data: projectData } = useProject()

  return {
    data: projectData?.transitionComponents,
  }
}

export const useCreateTransitionComponent = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return async (component: TransitionComponent) => {
    if (projectData) {
      await mutateAsync({
        ...projectData,
        transitionComponents:
          projectData.transitionComponents.concat(component),
      })
    }
  }
}

interface TransitionEditParams {
  values: TransitionFormValues
  transition: Transition
  onSuccess: VoidFunction
}

export const useTransitionEdit = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({ onSuccess, transition, values }: TransitionEditParams) => {
    if (projectData) {
      let newProjectData: ProjectData = {
        ...projectData,
      }
      let actionId: string

      if (values.newAction) {
        const newTransitionComponentDto = getNewTransitionComponent(
          values.newAction
        )
        newProjectData.transitionComponents =
          projectData.transitionComponents.concat(newTransitionComponentDto)
        actionId = newTransitionComponentDto.id
      } else {
        actionId = values.actionId as string
      }

      const newTransitions = projectData.flow.transitions.map((item) => {
        if (item.id !== transition.id) {
          return item
        }

        return {
          id: transition.id,
          actionId,
          result: values.result,
          initiator: values.initiator,
          relatedTransitionId:
            values.relatedTransitionId === EMPTY_VALUE_ID
              ? undefined
              : values.relatedTransitionId,
          position: values.position,
        }
      })

      newProjectData.flow.transitions = newTransitions

      if (values.result) {
        newProjectData.flow = getUpdateRightScreenNameFlow({
          position: transition.position,
          name: values.result,
          projectData: newProjectData,
        })
      }

      mutateAsync(newProjectData, {
        onSuccess,
      })
    }
  }
}

interface EditTransitionComponentParams {
  component: TransitionComponent
  onSuccess: VoidFunction
}

export const useEditTransitionComponent = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({ component, onSuccess }: EditTransitionComponentParams) => {
    if (projectData) {
      mutateAsync(
        {
          ...projectData,
          transitionComponents: projectData.transitionComponents.map((item) =>
            item.id === component.id ? component : item
          ),
        },
        { onSuccess }
      )
    }
  }
}

export const useUpdateTransition = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return async (transitionId: string, values: Omit<Transition, 'id'>) => {
    if (projectData) {
      const newTransitions = projectData.flow.transitions.map((item) => {
        if (item.id !== transitionId) {
          return item
        }
        return {
          id: transitionId,
          ...values,
        }
      })

      await mutateAsync({
        ...projectData,
        flow: {
          ...projectData.flow,
          transitions: newTransitions,
        },
      })
    }
  }
}

export const useClearTransition = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return (transitionId: string, onSuccess: VoidFunction) => {
    if (projectData) {
      mutateAsync(
        {
          ...projectData,
          flow: {
            ...projectData.flow,
            transitions: projectData.flow.transitions.map((item) => {
              if (item.id !== transitionId) return item

              return {
                id: item.id,
                actionId: undefined,
                initiator: undefined,
                position: item.position,
                relatedTransitionId: undefined,
                result: '',
              }
            }),
          },
        },
        { onSuccess }
      )
    }
  }
}

interface GetUpdateRightScreenNameFlowParams {
  position: XYPosition
  name: string
  projectData: ProjectData
}

type GetUpdateRightScreenNameFlowResponse = Flow

export const getUpdateRightScreenNameFlow = ({
  name,
  position,
  projectData,
}: GetUpdateRightScreenNameFlowParams): GetUpdateRightScreenNameFlowResponse => {
  let newFlow: Flow

  const rightScreen = projectData.flow.screens.find(
    (item) =>
      item.position.y === position.y &&
      item.position.x === position.x + TRANSITION_WIDTH + DEFAULT_OFFSET
  )

  let newScreenFragments = {
    ...projectData.screenFragments,
  }

  if (rightScreen) {
    newFlow = {
      ...projectData.flow,
      screens: projectData.flow.screens.map((screen) => {
        if (screen.id !== rightScreen.id) return screen

        return {
          ...screen,
          name,
        }
      }),
    }
  } else {
    const { transition, screen } = createScreenWithTransition({
      name,
      position: {
        ...position,
        x: position.x + TRANSITION_WIDTH + DEFAULT_OFFSET,
      },
      first: 'transition',
    })

    newScreenFragments[screen.id] = getInitialScreenFragments()

    newFlow = {
      ...projectData.flow,
      screens: projectData.flow.screens.concat(screen),
      transitions: projectData.flow.transitions.concat(transition),
    }
  }

  return newFlow
}

export const getDefaultRole = () => i18n.t('main')

export const useRoles = () => {
  const { data: projectData } = useProject()
  return {
    data: projectData?.roles,
  }
}

export const useAddRole = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return (role: string, onSuccess: VoidFunction) => {
    if (projectData) {
      mutateAsync(
        {
          ...projectData,
          roles: projectData.roles.concat(role),
        },
        { onSuccess }
      )
    }
  }
}

interface ShiftToBottomMap {
  element: FlowElement
  fromPositionY: number
}

const shiftToBottomMap = ({ element, fromPositionY }: ShiftToBottomMap) => {
  if (element.position.y > fromPositionY) {
    return {
      ...element,
      position: {
        ...element.position,
        y: element.position.y + DEFAULT_OFFSET + BASIC_HEIGHT,
      },
    }
  }

  return element
}

interface ShiftToTopMap {
  element: FlowElement
  fromPositionY: number
}

const shiftToTopMap = ({ element, fromPositionY }: ShiftToTopMap) => {
  if (element.position.y > fromPositionY) {
    return {
      ...element,
      position: {
        ...element.position,
        y: element.position.y - DEFAULT_OFFSET - BASIC_HEIGHT,
      },
    }
  }

  return element
}

interface ShiftToRightWithPairMapParams {
  element: FlowElement
  fromPositionX: number
  shiftCount?: number
  forOnlyPositionY?: number
}

const shiftToRightWithPairMap = ({
  element,
  fromPositionX,
  shiftCount = 1,
  forOnlyPositionY,
}: ShiftToRightWithPairMapParams) => {
  if (!forOnlyPositionY || element.position.y === forOnlyPositionY) {
    if (element.position.x > fromPositionX) {
      return {
        ...element,
        position: {
          ...element.position,
          x: element.position.x + (DEFAULT_OFFSET + PAIR_WIDTH) * shiftCount,
        },
      }
    }
  }

  return element
}

interface ShiftToLeftWithPairMapParams {
  element: FlowElement
  fromPositionX: number
  shiftCount?: number
  forOnlyPositionY?: number
}

const shiftToLeftWithPairMap = ({
  element,
  fromPositionX,
  shiftCount = 1,
  forOnlyPositionY,
}: ShiftToLeftWithPairMapParams) => {
  if (!forOnlyPositionY || element.position.y === forOnlyPositionY) {
    if (element.position.x > fromPositionX) {
      return {
        ...element,
        position: {
          ...element.position,
          x: element.position.x - (DEFAULT_OFFSET + PAIR_WIDTH) * shiftCount,
        },
      }
    }
  }

  return element
}

interface CreateNewTransitionActionParams {
  fromTransitionPosition: XYPosition
  onSuccess: (newTransition: Transition) => void
  flow: Flow | undefined
}

export const useCreateNewTransitionAction = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({
    fromTransitionPosition,
    onSuccess,
    flow,
  }: CreateNewTransitionActionParams) => {
    if (!flow || !projectData) return

    let insertPositionY =
      fromTransitionPosition.y + DEFAULT_OFFSET + TRANSITION_HEIGHT
    let needSearch = true

    while (needSearch) {
      const searchTransition = flow.transitions.find(
        (item) =>
          item.position.y === insertPositionY &&
          item.position.x === fromTransitionPosition.x
      )

      const searchPositionRightScreen = flow.screens.find(
        (screen) =>
          screen.position.x ===
            fromTransitionPosition.x + TRANSITION_WIDTH + DEFAULT_OFFSET &&
          screen.position.y === insertPositionY
      )

      needSearch = Boolean(!searchTransition && searchPositionRightScreen)

      if (needSearch) {
        insertPositionY += TRANSITION_HEIGHT + DEFAULT_OFFSET
      }
    }

    const { screen, transition } = createScreenWithTransition({
      name: '',
      first: 'transition',
      position: {
        ...fromTransitionPosition,
        y: insertPositionY,
      },
    })

    const shiftFromPositionY =
      insertPositionY - DEFAULT_OFFSET - TRANSITION_HEIGHT

    const newScreenFragments = {
      ...projectData.screenFragments,
      [screen.id]: getInitialScreenFragments(),
    }

    mutateAsync(
      {
        ...projectData,
        flow: {
          ...projectData.flow,
          screens: projectData.flow.screens
            .map((element) =>
              shiftToBottomMap({
                element,
                fromPositionY: shiftFromPositionY,
              })
            )
            .concat(screen),
          transitions: projectData.flow.transitions
            .map((element) =>
              shiftToBottomMap({
                element,
                fromPositionY: shiftFromPositionY,
              })
            )
            .concat(transition),
        } as any,
        screenFragments: newScreenFragments,
      },
      {
        onSuccess: () => onSuccess(transition),
      }
    )
  }
}

interface CreateNewTransitionResultParams {
  fromTransition: Transition
}

export const useCreateNewTransitionResult = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({ fromTransition }: CreateNewTransitionResultParams) => {
    if (projectData) {
      const newScreen = createScreen({
        name: '',
        position: {
          x: fromTransition.position.x + DEFAULT_OFFSET + TRANSITION_WIDTH,
          y: fromTransition.position.y + DEFAULT_OFFSET + BASIC_HEIGHT,
        },
      })

      const newScreenFragments = {
        ...projectData.screenFragments,
        [newScreen.id]: getInitialScreenFragments(),
      }

      mutateAsync({
        ...projectData,
        flow: {
          ...projectData.flow,
          screens: projectData.flow.screens
            .map((element) =>
              shiftToBottomMap({
                element,
                fromPositionY: fromTransition.position.y,
              })
            )
            .concat(newScreen),
          transitions: projectData.flow.transitions.map((element) =>
            shiftToBottomMap({
              element,
              fromPositionY: fromTransition.position.y,
            })
          ),
        } as any,
        screenFragments: newScreenFragments,
      })
    }
  }
}

interface AddColumnToRightOfScreenParams {
  fromPositionX: number
}

export const useAddColumnToRightOfScreen = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({ fromPositionX }: AddColumnToRightOfScreenParams) => {
    if (projectData) {
      const columnPositionX = fromPositionX + DEFAULT_OFFSET + SCREEN_WIDTH

      const allElements = [
        ...projectData.flow.screens,
        ...projectData.flow.transitions,
      ]
      const allYPositions = allElements.map((element) => element.position.y)
      const uniqYPositions = new Set(allYPositions)

      let newScreens: Screen[] = []
      let newTransitions: Transition[] = []

      uniqYPositions.forEach((positionY) => {
        const { screen, transition } = createScreenWithTransition({
          name: '',
          first: 'transition',
          position: {
            x: columnPositionX,
            y: positionY,
          },
        })

        newScreens.push(screen)
        newTransitions.push(transition)
      })

      const newScreenFragments = {
        ...projectData.screenFragments,
      }

      newScreens.forEach((newScreen) => {
        newScreenFragments[newScreen.id] = getInitialScreenFragments()
      })

      mutateAsync({
        ...projectData,
        flow: {
          ...projectData.flow,
          screens: projectData.flow.screens
            .map((element) =>
              shiftToRightWithPairMap({
                element,
                fromPositionX,
              })
            )
            .concat(newScreens),
          transitions: projectData.flow.transitions
            .map((element) =>
              shiftToRightWithPairMap({
                element,
                fromPositionX,
              })
            )
            .concat(newTransitions),
        } as any,
        screenFragments: newScreenFragments,
      })
    }
  }
}

interface CreateEdgeParams {
  transitionId: string
  screenId: string
}

const createEdge = ({ screenId, transitionId }: CreateEdgeParams): Edge => ({
  id: createUUID(),
  source: transitionId,
  target: screenId,
  type: 'smoothstep',
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 10,
    height: 10,
    color: '#000',
  },
  style: {
    strokeWidth: 2,
    stroke: '#000',
  },
})

export const useFlowEdges = (): Edge[] => {
  const { data: flow } = useFlow()

  if (!flow) return []

  const getBottomPositionY = () => {
    const allElements = [...flow.screens, ...flow.transitions]
    const allElementYPositions = allElements.map(
      (element) => element.position.y
    )
    const bottomPositionY = Math.max(...allElementYPositions)
    return bottomPositionY
  }

  const bottomPositionY = getBottomPositionY()

  const edges: Edge[] = flow.transitions
    .filter(
      (transition) => transition.actionId && !transition.relatedTransitionId
    )
    .reduce<Edge[]>((acc, transition) => {
      let newEdges: Edge[] = []

      const rightScreen = flow.screens.find(
        (screen) =>
          screen.position.x ===
            transition.position.x + TRANSITION_WIDTH + DEFAULT_OFFSET &&
          screen.position.y === transition.position.y
      )

      if (rightScreen) {
        newEdges.push(
          createEdge({
            screenId: rightScreen.id,
            transitionId: transition.id,
          })
        )
      }

      let searchPositionY =
        transition.position.y + TRANSITION_HEIGHT + DEFAULT_OFFSET

      while (true) {
        const searchTransition = flow.transitions.find(
          (item) =>
            item.position.y === searchPositionY &&
            item.position.x === transition.position.x
        )

        const searchPositionRightScreen = flow.screens.find(
          (screen) =>
            screen.position.x ===
              transition.position.x + TRANSITION_WIDTH + DEFAULT_OFFSET &&
            screen.position.y === searchPositionY
        )

        const isBottom = searchPositionY > bottomPositionY

        const isEndOfSearch = isBottom || Boolean(searchTransition)

        if (isEndOfSearch) {
          return acc.concat(newEdges)
        }

        if (searchPositionRightScreen) {
          newEdges.push(
            createEdge({
              screenId: searchPositionRightScreen.id,
              transitionId: transition.id,
            })
          )
        }

        searchPositionY += TRANSITION_HEIGHT + DEFAULT_OFFSET
      }
    }, [])

  return edges
}

interface AddScreenWithTransitionInRowParams {
  position: XYPosition
}

export const useAddScreenWithTransitionInRow = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({ position }: AddScreenWithTransitionInRowParams) => {
    if (projectData) {
      const { screen, transition } = createScreenWithTransition({
        first: 'transition',
        name: '',
        position,
      })

      const newScreenFragments = {
        ...projectData.screenFragments,
        [screen.id]: getInitialScreenFragments(),
      }

      mutateAsync({
        ...projectData,
        flow: {
          ...projectData.flow,
          screens: projectData.flow.screens
            .map((element) =>
              element.position.y === position.y
                ? shiftToRightWithPairMap({
                    element,
                    fromPositionX: position.x - 1,
                  })
                : element
            )
            .concat(screen),
          transitions: projectData.flow.transitions
            .map((element) =>
              element.position.y === position.y
                ? shiftToRightWithPairMap({
                    element,
                    fromPositionX: position.x - 1,
                  })
                : element
            )
            .concat(transition),
        } as any,
        screenFragments: newScreenFragments,
      })
    }
  }
}

interface DeleteRowParams {
  positionY: number
}

export const useDeleteRow = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({ positionY }: DeleteRowParams) => {
    if (projectData) {
      const transitionsAndScreens = [
        ...projectData.flow.screens,
        ...projectData.flow.transitions,
      ]
      const yCoords = transitionsAndScreens.map((item) => item.position.y)
      const uniqYCoords = [...new Set(yCoords)]
      const hasOnlyOneRow = uniqYCoords.length === 1

      if (!hasOnlyOneRow) {
        const newProjectData: ProjectData = {
          ...projectData,
          flow: {
            ...projectData.flow,
            screens: projectData.flow.screens
              .filter((item) => item.position.y !== positionY)
              .map((element) =>
                shiftToTopMap({
                  element,
                  fromPositionY: positionY,
                })
              ),
            transitions: projectData.flow.transitions
              .filter((item) => item.position.y !== positionY)
              .map((element) =>
                shiftToTopMap({
                  element,
                  fromPositionY: positionY,
                })
              ),
          },
        } as any

        mutateAsync(
          getProjectDataWithActualTransitionComponents(newProjectData)
        )
      }
    }
  }
}

interface DeleteColumnParams {
  positionX: number
}

export const useDeleteColumn = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({ positionX }: DeleteColumnParams) => {
    if (projectData) {
      const xTransitionCoords = projectData.flow.transitions.map(
        (item) => item.position.x
      )
      const uniqXTransitionCoords = [...new Set(xTransitionCoords)]
      const hasOnlyOnePair = uniqXTransitionCoords.length === 1

      if (!hasOnlyOnePair) {
        const newProjectData: ProjectData = {
          ...projectData,
          flow: {
            ...projectData.flow,
            screens: projectData.flow.screens
              .filter(
                (item) =>
                  item.position.x < positionX ||
                  item.position.x > positionX + PAIR_WIDTH
              )
              .map((element) =>
                shiftToLeftWithPairMap({
                  element,
                  fromPositionX: positionX + PAIR_WIDTH,
                })
              ),
            transitions: projectData.flow.transitions
              .filter(
                (item) =>
                  item.position.x < positionX ||
                  item.position.x > positionX + PAIR_WIDTH
              )
              .map((element) =>
                shiftToLeftWithPairMap({
                  element,
                  fromPositionX: positionX + PAIR_WIDTH,
                })
              ),
          } as any,
        }

        mutateAsync(
          getProjectDataWithActualTransitionComponents(newProjectData)
        )
      }
    }
  }
}

interface DeletePairParams {
  screenPosition: XYPosition
}

export const useDeletePair = () => {
  const { data: projectData } = useProject()
  const { mutateAsync } = useUpdateProjectMutation()

  return ({ screenPosition }: DeletePairParams) => {
    if (projectData) {
      const xTransitionCoords = projectData.flow.transitions.map(
        (item) => item.position.x
      )
      const uniqXTransitionCoords = [...new Set(xTransitionCoords)]
      const hasOnlyOnePair = uniqXTransitionCoords.length === 1

      if (!hasOnlyOnePair) {
        const newProjectData: ProjectData = {
          ...projectData,
          flow: {
            ...projectData.flow,
            screens: projectData.flow.screens
              .filter(
                (item) =>
                  !(
                    item.position.x === screenPosition.x &&
                    item.position.y === screenPosition.y
                  )
              )
              .map((element) =>
                shiftToLeftWithPairMap({
                  element,
                  fromPositionX: screenPosition.x,
                  forOnlyPositionY: screenPosition.y,
                })
              ),
            transitions: projectData.flow.transitions
              .filter(
                (item) =>
                  !(
                    item.position.x ===
                      screenPosition.x - DEFAULT_OFFSET - TRANSITION_WIDTH &&
                    item.position.y === screenPosition.y
                  )
              )
              .map((element) =>
                shiftToLeftWithPairMap({
                  element,
                  fromPositionX: screenPosition.x,
                  forOnlyPositionY: screenPosition.y,
                })
              ),
          } as any,
        }

        mutateAsync(
          getProjectDataWithActualTransitionComponents(newProjectData)
        )
      }
    }
  }
}

export const useScreenHasLeftTransition = (screenPosition: XYPosition) => {
  const { data: projectData } = useProject()

  if (projectData) {
    const leftTransition = projectData.flow.transitions.find(
      (item) =>
        item.position.y === screenPosition.y &&
        item.position.x === screenPosition.x - TRANSITION_WIDTH - DEFAULT_OFFSET
    )

    return Boolean(leftTransition)
  }

  return false
}
