import { ReportAssessmentType } from 'widgets/report/types'
import { getReportAssessmentAttributes } from 'entities/assessment'

import s from './AssessmentDigit.module.css'
import classNames from 'classnames'

interface AssessmentDigitProps {
  count: React.ReactNode
  type: ReportAssessmentType
  alwaysShowColor?: boolean
  size?: 'small' | 'medium'
  noPadding?: boolean
}

export const AssessmentDigit = ({
  count,
  type,
  alwaysShowColor,
  size = 'medium',
  noPadding,
}: AssessmentDigitProps) => (
  <div
    className={classNames(s.assessmentWrapper, {
      [s.noPadding]: noPadding,
    })}
  >
    <div
      className={classNames(s.assessment, s[size])}
      style={{
        backgroundColor:
          count || alwaysShowColor
            ? getReportAssessmentAttributes()[type].color
            : undefined,
      }}
    >
      {count}
    </div>
  </div>
)
