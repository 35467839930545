import * as yup from 'yup'

export interface ReportAssessmentBundlingFormValues {
  criticality: string
  findType: string
  findDescription: string
  findName: string
  heuristicId: string
  recommendation: string
  customFindName: string
  customFindDescription: string
  customRecommendation: string
  searchHeuristicId: string
}

export const reportAssessmentBundlingValidationSchema = yup.object({
  findName: yup.string().required(),
  findDescription: yup.string().required(),
  findType: yup.string().required(),
  customFindName: yup.string().defined(),
  customFindDescription: yup.string().defined(),
  customRecommendation: yup.string().defined(),
  criticality: yup
    .string()
    .required()
    .when('findType', {
      is: (value: any) => value === 'ux-good' || value === 'bug',
      then: (schema) => schema.notRequired(),
    }),
  recommendation: yup.string().required(),
  heuristicId: yup.string().defined(),
  searchHeuristicId: yup.string().defined(),
})

export const defaultReportAssessmentBundlingFormValues: ReportAssessmentBundlingFormValues =
  {
    criticality: '',
    findType: '',
    findDescription: '',
    findName: '',
    heuristicId: '',
    searchHeuristicId: '',
    recommendation: '',
    customFindName: '',
    customFindDescription: '',
    customRecommendation: '',
  }
