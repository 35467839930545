import { useEffect, useMemo, useRef, useState } from 'react'
import { fabric } from 'fabric'
import { debounce } from 'lodash-es'
import { drawCanvas } from './lib'
import {
  useAddScreenFragment,
  useScreenFragments,
  useUpdateScreenFragment,
} from 'entities/assessment'

import s from './ScreenCanvas.module.css'
import { useFlow } from 'entities/flow'
import {
  MarkerAssessmentType,
  ScreenFragmentWithAssessmentType,
  ScrollPosition,
} from './types'
import { useHasWritePermission } from 'entities/permissions'
import { useProjectContext } from 'features/head/context'

interface ScreenCanvasProps {
  screenId: string
  fragmentId: string
  setFragmentId?: (value: string) => void
  content?: React.ReactNode
  hidden?: boolean
}

const FRAGMENT_SCROLL_OFFSET = 25

export const ScreenCanvas = ({
  screenId,
  setFragmentId,
  fragmentId,
  content,
  hidden = false,
}: ScreenCanvasProps) => {
  const [canvasSize, setCanvasSize] = useState<{
    height: number
    width: number
  } | null>(null)
  const scrollPositionRef = useRef<ScrollPosition | null>(null)
  const canvasEl = useRef<HTMLCanvasElement>(null)
  const wrapperEl = useRef<HTMLDivElement>(null)
  const hoveredFragmentIdRef = useRef<string | null>(null)

  const fragments = useScreenFragments({ screenId })

  const { data: flow } = useFlow()

  const screen = flow?.screens.find((item) => item.id === screenId)

  const debouncedScrollHandle = useMemo(
    () =>
      debounce((position: ScrollPosition) => {
        scrollPositionRef.current = position
      }, 300),
    []
  )

  const fragmentsWithAssessmentType:
    | ScreenFragmentWithAssessmentType[]
    | undefined = useMemo(() => {
    return fragments?.map((fragment) => {
      const markerAssessmentType: MarkerAssessmentType = []

      const assessments = fragment.assessments

      if (assessments.length > 0) {
        assessments.forEach((assessment) => {
          if (assessment.findType === 'ux-good') {
            markerAssessmentType.push(assessment.findType)
          }
          if (assessment.findType === 'ux-problem') {
            if (assessment.type === 'good') {
              markerAssessmentType.push('ux-problem-low')
            }
            if (assessment.type === 'medium') {
              markerAssessmentType.push('ux-problem-medium')
            }
            if (assessment.type === 'bad') {
              markerAssessmentType.push('ux-problem-high')
            }
          }
        })
      }

      return {
        ...fragment,
        markerAssessmentType,
      }
    })
  }, [fragments])

  const addScreenFragment = useAddScreenFragment()
  const updateScreenFragment = useUpdateScreenFragment()

  const fragment = fragments?.find((item) => item.id === fragmentId)

  const { isAssessment, isMaster } = useProjectContext()

  const hasWritePermission = fragment?.isCustom || isMaster

  useEffect(() => {
    if (canvasEl.current && fragmentsWithAssessmentType && canvasSize) {
      const canvas = new fabric.Canvas(canvasEl.current, {
        hoverCursor: 'pointer',
        moveCursor: 'pointer',
        height: canvasSize.height,
        width: canvasSize.width,
      })

      drawCanvas({
        canvas,
        screenId,
        fragments: fragmentsWithAssessmentType,
        activeFragmentId: fragmentId,
        setActiveFragmentId: setFragmentId || (() => {}),
        rectRef: hoveredFragmentIdRef,
        addScreenFragment,
        updateScreenFragment,
        readOnly: !hasWritePermission,
      })

      wrapperEl.current?.scrollTo({
        left: scrollPositionRef.current?.left || 0,
        top: scrollPositionRef.current?.top || 100,
      })

      return () => {
        canvas.dispose()
      }
    }
  }, [
    screenId,
    fragmentId,
    fragmentsWithAssessmentType,
    setFragmentId,
    canvasSize,
    addScreenFragment,
    updateScreenFragment,
    hasWritePermission,
    isAssessment,
  ])

  useEffect(() => {
    if (fragmentId) {
      const fragment = fragments?.find((item) => item.id === fragmentId)

      if (fragment) {
        wrapperEl.current?.scrollTo({
          left: fragment.position.x
            ? fragment.position.x - FRAGMENT_SCROLL_OFFSET
            : 0,
          top: fragment.position.y
            ? fragment.position.y - FRAGMENT_SCROLL_OFFSET
            : 0,
        })
      }
    }
  }, [fragmentId, fragments])

  return (
    <div
      className={s.wrapper}
      ref={wrapperEl}
      onScroll={(e) => {
        debouncedScrollHandle({
          left: (e.target as any).scrollLeft,
          top: (e.target as any).scrollTop,
        })
      }}
      style={
        hidden
          ? {
              display: 'none',
            }
          : undefined
      }
    >
      <div className={s.canvasWrapper}>
        <canvas ref={canvasEl}></canvas>
      </div>
      {screen?.previewImageUrl && (
        <img
          src={screen?.previewImageUrl}
          id="canvas-image"
          alt="canvas"
          className={s.image}
          onLoad={(event) => {
            setCanvasSize({
              height: (event.target as HTMLImageElement).height,
              width: (event.target as HTMLImageElement).width,
            })
          }}
        />
      )}

      {content}
    </div>
  )
}
