import { createTheme } from '@mui/material'

export const theme = createTheme()

// export const theme = createTheme({
//   palette: {
//     primary: {
//       main: 'rgba(0, 0, 0, 0.5)',
//     },
//   },
// })
