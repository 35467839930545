import i18n from 'shared/i18n/i18n'
import { Option } from './types'
import { getReportAssessmentAttributes } from 'entities/assessment'
import { TFunction } from 'i18next'

const assessmentAttributes = getReportAssessmentAttributes()

export const getFindTypeOptions = (): Option[] => [
  {
    name: i18n.t('usabilityGood'),
    value: 'ux-good',
    icon: assessmentAttributes['ux-good'].icon,
  },
  {
    name: i18n.t('usabilityProblem'),
    value: 'ux-problem',
    icon: assessmentAttributes['ux-problem-low'].icon,
  },
  {
    name: i18n.t('businessTask'),
    value: 'task',
    icon: assessmentAttributes.task.icon,
  },
  {
    name: i18n.t('bug'),
    value: 'bug',
    icon: assessmentAttributes.bug.icon,
  },
]

export const getCriticalityOptions = (): Option[] => [
  {
    name: i18n.t('low'),
    value: 'good',
    icon: assessmentAttributes['ux-problem-low'].icon,
  },
  {
    name: i18n.t('medium'),
    value: 'medium',
    icon: assessmentAttributes['ux-problem-medium'].icon,
  },
  {
    name: i18n.t('high'),
    value: 'bad',
    icon: assessmentAttributes['ux-problem-high'].icon,
  },
]

export const getReportAssessmentFilterOptions = (): Option[] => [
  {
    name: i18n.t('usabilityGood'),
    value: 'ux-good',
    icon: assessmentAttributes['ux-good'].icon,
  },
  {
    name: i18n.t('businessTask'),
    value: 'task',
    icon: assessmentAttributes.task.icon,
  },
  {
    name: i18n.t('bug'),
    value: 'bug',
    icon: assessmentAttributes.bug.icon,
  },
  {
    value: 'ux-problem-high',
    name: `${i18n.t('usabilityProblem')} (${i18n.t('criticality')} ${i18n.t(
      'high'
    )})`,
    icon: assessmentAttributes['ux-problem-high'].icon,
  },
  {
    value: 'ux-problem-medium',
    name: `${i18n.t('usabilityProblem')} (${i18n.t('criticality')} ${i18n.t(
      'medium'
    )})`,
    icon: assessmentAttributes['ux-problem-medium'].icon,
  },
  {
    value: 'ux-problem-low',
    name: `${i18n.t('usabilityProblem')} (${i18n.t('criticality')} ${i18n.t(
      'low'
    )})`,
    icon: assessmentAttributes['ux-problem-low'].icon,
  },
]

export const getFieldLabelByName = (name: string, t: TFunction) => {
  if (name === 'heuristicId') return t('heuristic')
  if (name === 'criticality') return t('criticality')
  if (name === 'findName') return t('nameBig')
  if (name === 'findDescription') return t('description')
  if (name === 'recommendation') return t('recommendation')
  if (name === 'tags') return t('tags')
  return ''
}
