import { InputAdornment, TextField, Typography } from '@mui/material'
import s from './HeuristicWithButton.module.css'
import { MouseEventHandler } from 'react'

import { ReactComponent as BookIcon } from '../../../assets/book.svg'
import { ReactComponent as StartIcon } from '../../../assets/star.svg'
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg'
import { ReactComponent as CloseIcon } from '../../../assets/close.svg'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

interface HeuristicWithButtonProps {
  active?: boolean
  name: string
  nameNegative?: string
  favorite?: boolean
  onButtonClick?: MouseEventHandler<HTMLDivElement>
  onTextClick: MouseEventHandler<HTMLDivElement>
  mode?: 'add' | 'remove'
  negative?: boolean
  disabled?: boolean
  buttonHidden?: boolean
  withoutButton?: boolean
}

export const HeuristicWithButton = ({
  active = false,
  mode,
  name,
  onButtonClick,
  onTextClick,
  disabled = false,
  buttonHidden = false,
  favorite = false,
  negative,
  nameNegative,
  withoutButton = false,
}: HeuristicWithButtonProps) => {
  const negativeName = nameNegative || name
  const heuristicName = negative ? negativeName : name
  const { t } = useTranslation()

  return (
    <div className={s.wrapper}>
      {mode === 'remove' ? (
        <TextField
          label={t('heuristic')}
          multiline
          value={heuristicName}
          onChange={() => null}
          fullWidth
          size="small"
          disabled
          InputLabelProps={{
            className: s.activeLabel,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div onClick={onButtonClick} className={s.closeIconWrapper}>
                  <CloseIcon />
                </div>
              </InputAdornment>
            ),
            className: classNames(s.text, s.activeColor),
            classes: {
              adornedEnd: s.activeColor,
              disabled: s.activeColor,
            },
          }}
        />
      ) : (
        <>
          <div
            className={classNames(s.textWrapper, {
              [s.disabled]: disabled || buttonHidden || withoutButton,
            })}
            onClick={onButtonClick}
          >
            <Typography className={s.text}>{heuristicName}</Typography>
            {favorite && (
              <StartIcon className={classNames(s.icon, s.starIcon)} />
            )}
            <PlusIcon className={classNames(s.icon, s.plusIcon)} />
          </div>
          <div className={s.detailsIconWrapper} onClick={onTextClick}>
            <BookIcon />
          </div>
        </>
      )}
    </div>
  )
  // return (
  //   <div className={s.wrapper}>
  //     {!withoutButton && (
  //       <IconButton
  //         disabled={disabled}
  //         onClick={onButtonClick}
  //         className={classNames(s.button, {
  //           [s.hiddenButton]: buttonHidden,
  //         })}
  //       >
  //         {mode === 'remove' ? (
  //           <RemoveIcon fontSize="large" />
  //         ) : (
  //           <AddIcon fontSize="large" />
  //         )}
  //       </IconButton>
  //     )}
  //     <div className={s.textWrapper} onClick={onTextClick}>
  //       {favorite && <StarIcon className={s.favoriteIcon} />}
  //       <Typography
  //         className={classNames(s.text, {
  //           [s.active]: active,
  //         })}
  //       >
  //         {negative ? negativeName : name}
  //       </Typography>
  //     </div>
  //   </div>
  // )
}
