import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { RemoteProjectMeta } from 'shared/types/projects'
import s from './ScenarioInfo.module.css'
import GridViewIcon from '@mui/icons-material/GridView'
import LanguageIcon from '@mui/icons-material/Language'
import PersonIcon from '@mui/icons-material/Person'
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid'
import ForkRightIcon from '@mui/icons-material/ForkRight'
import classNames from 'classnames'

interface ScenarioInfoProps {
  projectId: string
  children?: React.ReactNode
  withoutBottomBorder?: boolean
}

export const ScenarioInfo = ({
  projectId,
  withoutBottomBorder,
  children,
}: ScenarioInfoProps) => {
  const encodedProjectMeta = localStorage.getItem(`metaData-${projectId}`)

  const { t } = useTranslation()

  if (!encodedProjectMeta) {
    return null
  }

  const decodedProjectMeta: RemoteProjectMeta = JSON.parse(encodedProjectMeta)

  const isMobile =
    decodedProjectMeta.platform.includes('obile') ||
    decodedProjectMeta.platform.includes('обил')

  return (
    <div
      className={classNames(s.wrapper, {
        [s.withoutBottomBorder]: withoutBottomBorder,
      })}
    >
      <div className={s.inputWrapper}>
        <div className={s.titleWithItemsWrapper}>
          <Typography
            variant="body2"
            classes={{
              root: s.scenarioTItle,
            }}
          >
            <span className={s.grey}>{t('scenario')}:</span>{' '}
            {decodedProjectMeta.title}
          </Typography>
          <div className={s.items}>
            <div className={s.item}>
              <GridViewIcon />
              <Typography
                variant="body2"
                classes={{
                  root: s.itemText,
                }}
              >
                {decodedProjectMeta.product}
              </Typography>
            </div>
            <div className={s.item}>
              {isMobile ? <TabletAndroidIcon /> : <LanguageIcon />}
              <Typography
                variant="body2"
                classes={{
                  root: s.itemText,
                }}
              >
                {decodedProjectMeta.platform}
              </Typography>
            </div>
            <div className={s.item}>
              <PersonIcon />
              <Typography
                variant="body2"
                classes={{
                  root: s.itemText,
                }}
              >
                {decodedProjectMeta.role}
              </Typography>
            </div>
            <div className={s.item}>
              <ForkRightIcon />
              <Typography
                variant="body2"
                classes={{
                  root: s.itemText,
                }}
              >
                {decodedProjectMeta.version}
              </Typography>
            </div>
          </div>
        </div>
        <div>{children}</div>
      </div>
    </div>
  )
}
