import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { TransitionComponent } from '../TransitionComponent/TransitionComponent'
import { useState } from 'react'
import { Transition, useClearTransition } from 'entities/flow'
import { TransitionEdit } from '../TransitionForm/TransitionEdit/TransitionEdit'
import { useHasWritePermission } from 'entities/permissions'
import i18n from 'shared/i18n/i18n'
import { useProjectContext } from 'features/head/context'

type Props = {
  transition: Transition
  onAddAction: VoidFunction
  onAddResult: VoidFunction
  addOptionsVisible: boolean
}

export const TransitionComponentPanel = ({
  transition,
  onAddAction,
  onAddResult,
  addOptionsVisible,
}: Props) => {
  const hasWritePermission = useHasWritePermission()

  const [isEditOpen, setEditOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const clearTransition = useClearTransition()

  const { isAssessment } = useProjectContext()

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
        border: '1px solid lightgrey',
        borderRadius: '8px',
        padding: '8px',
        width: 'calc(100% - 48px)',
      }}
    >
      <TransitionComponent transition={transition} />

      {hasWritePermission && !isAssessment && (
        <IconButton
          title={i18n.t('transitionOptions')}
          onClick={handleMenuClick}
        >
          <MoreVertIcon />
        </IconButton>
      )}

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setEditOpen(true)
            setAnchorEl(null)
          }}
        >
          {i18n.t('edit')}
        </MenuItem>

        {addOptionsVisible && (
          <>
            <MenuItem
              onClick={() => {
                onAddAction()
                setAnchorEl(null)
              }}
            >
              {i18n.t('addAction')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                onAddResult()
                setAnchorEl(null)
              }}
            >
              {i18n.t('addResult')}
            </MenuItem>
          </>
        )}

        <MenuItem
          onClick={() => {
            clearTransition(transition.id, () => setAnchorEl(null))
          }}
        >
          {i18n.t('remove')}
        </MenuItem>
      </Menu>

      {hasWritePermission && !isAssessment && (
        <TransitionEdit
          transition={transition}
          isOpen={isEditOpen}
          onClose={() => setEditOpen(false)}
        />
      )}
    </Box>
  )
}
