import { Flow } from 'entities/flow/types'
import { createUUID } from 'shared/lib/uuid'
import { DEFAULT_OFFSET, INITIAL_POSITION, TRANSITION_WIDTH } from './config'

export const getInitialFlow = (initialScreenId: string): Flow => ({
  id: 'flow-1',
  name: 'initial flow',
  screens: [
    {
      id: initialScreenId,
      position: {
        ...INITIAL_POSITION,
        x: INITIAL_POSITION.x + TRANSITION_WIDTH + DEFAULT_OFFSET,
      },
      fullImageUrl: undefined,
      previewImageUrl: undefined,
      name: '',
      imageFileName: undefined,
    },
  ],
  transitions: [
    {
      id: createUUID(),
      actionId: undefined,
      result: '',
      initiator: undefined,
      position: INITIAL_POSITION,
      relatedTransitionId: undefined,
    },
  ],
})
