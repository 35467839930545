import { Transition } from 'entities/flow'
import { PropsWithChildren, createContext, useContext, useState } from 'react'
import { TransitionEdit } from '../TransitionForm/TransitionEdit/TransitionEdit'

interface TransitionContextValues {
  setTransition: (value: Transition) => void
}

export const TransitionContext = createContext<TransitionContextValues>({
  setTransition: () => {},
})

export const TransitionProvider = (props: PropsWithChildren) => {
  const [transition, setTransition] = useState<Transition | null>(null)

  return (
    <>
      <TransitionContext.Provider
        value={{
          setTransition,
        }}
      >
        {props.children}
      </TransitionContext.Provider>
      {transition && (
        <TransitionEdit
          transition={transition}
          isOpen
          onClose={() => setTransition(null)}
        />
      )}
    </>
  )
}

export const useTransitionContext = () => useContext(TransitionContext)
