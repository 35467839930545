import { ReportAssessmentType } from 'widgets/report/types'
import { AssessmentAttribute, FindType } from './types'

import { ReactComponent as BugIcon } from '../../assets/bug.svg'
import { ReactComponent as UxProblemHighIcon } from '../../assets/high.svg'
import { ReactComponent as UxProblemLowIcon } from '../../assets/low.svg'
import { ReactComponent as UxProblemMediumIcon } from '../../assets/medium.svg'
import { ReactComponent as OkIcon } from '../../assets/ok.svg'
import { ReactComponent as TaskIcon } from '../../assets/task.svg'

export const getAssessmentAttributes = (): Record<
  FindType | 'na',
  AssessmentAttribute
> => ({
  'ux-problem': {
    color: '#F2D0C9',
  },
  'ux-good': {
    color: '#DFECD1',
  },
  bug: {
    color: '#F4EAC5',
  },
  task: {
    color: '#d9daea',
  },
  na: {
    color: '#d2d2d2',
  },
})

export const getReportAssessmentAttributes = (): Record<
  ReportAssessmentType,
  AssessmentAttribute
> => ({
  'ux-problem-high': {
    color: `rgba(255, 168, 160, 1)`,
    icon: <UxProblemHighIcon />,
  },
  'ux-problem-medium': {
    color: `rgba(255, 198, 147, 1)`,
    icon: <UxProblemMediumIcon />,
  },
  'ux-problem-low': {
    color: `rgba(253, 228, 167, 1)`,
    icon: <UxProblemLowIcon />,
  },
  'ux-good': {
    color: `rgba(191, 242, 149, 1)`,
    icon: <OkIcon />,
  },
  bug: {
    color: `rgba(254, 217, 232, 1)`,
    icon: <BugIcon />,
  },
  task: {
    color: `rgba(212, 222, 255, 1)`,
    icon: <TaskIcon />,
  },
})

export const assessmentTypes: FindType[] = [
  'ux-good',
  'ux-problem',
  'task',
  'bug',
]

export const findTypeAvailableFields: Record<FindType, string[]> = {
  'ux-good': [
    'heuristicId',
    'findName',
    'findDescription',
    'recommendation',
    'tags',
  ],
  'ux-problem': [
    'heuristicId',
    'criticality',
    'findName',
    'findDescription',
    'recommendation',
    'tags',
  ],
  bug: ['findName', 'findDescription', 'recommendation', 'tags'],
  task: ['findName', 'findDescription', 'recommendation', 'tags'],
}
