import * as yup from 'yup'

export interface FindFormValues {
  findName: string
  findType: string
  findDescription: string
  criticality: string
  recommendation: string
  heuristicId: string
  tags: string[]
}

export const findValidationSchema = yup.object({
  findName: yup
    .string()
    .required()
    .when('findType', {
      is: (value: any) => value === 'ux-good',
      then: (schema) => schema.notRequired(),
    }),
  findType: yup.string().required(),
  findDescription: yup
    .string()
    .required()
    .when('findType', {
      is: (value: any) => value === 'ux-good',
      then: (schema) => schema.notRequired(),
    }),
  criticality: yup
    .string()
    .required()
    .when('findType', {
      is: (value: any) => value === 'ux-good' || value === 'bug',
      then: (schema) => schema.notRequired(),
    }),
  recommendation: yup
    .string()
    .required()
    .when('findType', {
      is: (value: any) => value === 'ux-good',
      then: (schema) => schema.notRequired(),
    }),
  heuristicId: yup.string().defined(),
  tags: yup.array(yup.string().required()),
})

export const defaultFindFormValues: FindFormValues = {
  criticality: '',
  findType: 'ux-good',
  findDescription: '',
  findName: '',
  heuristicId: '',
  recommendation: '',
  tags: [],
}
